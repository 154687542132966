@charset "UTF-8";

@mixin TMOBI300 {
    font-family: 'TMOBI300';
    font-weight: 400;
}

@mixin TMOBI500 {
    font-family: 'TMOBI500';
    font-weight: 600;
}

@mixin TMOBI700 {
    font-family: 'TMOBI700';
    font-weight: 900;
}

/* reset */
// 롱탭 비활성화
* {
    // touch-action: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    // user-drag: none;
}

input {
    -webkit-user-select: auto;
    user-select: auto;
}

// /* Correct `block` display not defined in IE 8/9. */
// article,
// aside,
// details,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// main,
// nav,
// section,
// summary {
// 	display: block;
// 	margin: 0;
// 	padding: 0;
// }
// /* elements */
// body {
// 	margin: 0;
// 	padding: 0;
// 	min-width: 320px;
// 	font-size: 13px;
// 	color: #222;
// 	line-height: 1.42857;
// 	-webkit-text-size-adjust: none;
// 	-ms-interpolation-mode: bicubic;
// 	font-family: 'Noto Sans KR', Helvetica Neue, Helvetica, Arial, sans-serif;
// 	width: 100%;
// 	letter-spacing:-0.4px;
// 	top: 0;
// 	left: 0;
// }
// body,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// div,
// li,
// dt,
// dd,
// input,
// textarea,
// button {
// 	font-family: 'Noto Sans KR', Helvetica Neue, Helvetica, Arial, sans-serif;
// 	margin: 0;
// 	padding: 0;
// }
// img,
// video,
// audio,
// object,
// embed,
// iframe {
// 	max-width: 100%;
// }
// img {
// 	border: 0 none;
// 	line-height: 0;
// 	-ms-interpolation-mode: bicubic;
// 	-webkit-image-rendering: optimizeQuality;
// }
// ul,
// ol,
// dl {
// 	list-style: none;
// 	margin: 0;
// 	padding: 0;
// }
// form {
// 	margin: 0;
// }
// table {
// 	width:100%;
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }
// th,
// td {
// 	padding: 0;
// }
// a {
// 	margin: 0;
// 	padding: 0;
// 	font-size: 100%;
// 	vertical-align: baseline;
// 	background: transparent;
// 	text-decoration: none;
// 	line-height: inherit;
// }
// input[type="text"],
// input[type="password"],
// input[type="reset"],
// input[type="submit"],
// input[type="tel"],
// input[type="number"],
// input[type="email"]{
// 	font-size: 13px;
// 	background: none;
// 	border-radius: 0px;
// 	border: none;
// 	-webkit-appearance: none;
// 	-webkit-border-radius: 0;
// }
// i {
// 	font-style: normal;
// 	display: inline-block;
// }
// textarea {
// 	overflow-y: auto;
// }
// fieldset {
// 	border: 0;
// 	margin: 0;
// 	padding: 0;
// }
// select{border:none;border-radius:0; -webkit-appearance: none;-webkit-border-radius: 0;}
// select::-ms-expand {
// 	display: none;
// }
// em{font-style: normal;}
// button{border:none;padding:0;margin:0;cursor:pointer;background: transparent;}
// br{font-family:dotum;}
// address{font-style: normal;}

// *{
// 	box-sizing:border-box;
// }

// /* style */

// .color-red{color:#e55545 !important;}

// .dl-style1{
// 	dt{
// 		clear:left;
// 		float:left;
// 	}
// 	dd{
// 		width:100%;
// 		&:after{
// 			content: "";
// 			display: table;
// 			clear: both;
// 		}
// 	}
// }

// .checkbox-type1{
// 	position: relative;
// 	display: inline-block;
// 	input[type="checkbox"]{
// 		position: absolute;
// 		opacity: 0;
// 		&:checked + label{
// 			color:#111;
// 			&:before{
// 				border-color: #0064ff;
// 				background: #0064ff;
// 			}
// 			&:after{display: inline-block;}
// 		}
// 	}
// 	label{
// 		position:relative;
// 		display: block;
// 		font-size:16px;
// 		color:#d8d8d8;
// 		@include TMOBI300;
// 		padding-left:30px;
// 		&:before{
// 			content: "";
// 			display: inline-block;
// 			width:20px;
// 			height:20px;
// 			border:1px solid #b3b3b3;
// 			border-radius: 3px;
// 			position: absolute;
// 			left:0;
// 		}
// 		&:after{
// 			content: "";
// 			display: none;
// 			position: absolute;
// 			top: 4px;
// 			left: 7px;
// 			width: 4px;
// 			height: 9px;
// 			border-right: 2px solid #fff;
// 			border-bottom: 2px solid #fff;
// 			transform: rotate(45deg);
// 		}
// 	}
// }

// .underline{
// 	position: relative;
// 	display: inline-block;
// 	&:before{
// 		content: "";
// 		position:absolute;
// 		left:0;
// 		bottom:0;
// 		display: inline-block;
// 		width:100%;
// 		height: 10px;;
// 		background: #97d3ff;
// 		z-index: -1;
// 	}
// }

// .pay-infobox{
// 	margin-top:34px;
// 	dl{
// 		border-top:8px solid #f5f5f5;
// 		padding:0 20px;
// 		dt{
// 			padding-top:15px;
// 			font-size:16px;
// 			@include TMOBI300;
// 			color:#111;
// 			line-height: 24px;
// 			word-break: keep-all;
// 		}
// 		dd{

// 			text-align: right;
// 			padding:15px 0 15px 120px;
// 			font-size:16px;
// 			&.border{
// 				border-bottom:1px solid #f1f1f1;
// 			}
// 			p{
// 				position: relative;
// 				overflow: hidden;
// 				text-overflow: ellipsis;
// 				white-space: nowrap;
// 				@include TMOBI500;
// 				color: #111;
// 				line-height: 24px;
// 				text-align: left;
// 				display: inline-block;
// 				max-width: 100%;
// 				&.icon01{
// 					padding-left:24px;
// 					&:before{
// 						content: "";
// 						position:absolute;
// 						top:-2px;
// 						left:0;
// 						display: inline-block;
// 						width:24px;
// 						height: 24px;
// 						background: url(../../assets/images/ic_map_evstation_blue.svg) no-repeat;
// 				background-size: cover;
// 					}
// 				}
// 			}
// 		}
// 		&.total-box{
// 			padding:10px 20px;
// 			dt{
// 				font-size:15px;
// 				color:#a1a3a6;
// 				padding-top:5px;
// 				&.last{
// 					width:120px;
// 					padding-top:16px;
// 					@include TMOBI500;
// 					font-size:18px;
// 					line-height: 26px;
// 					color:#111;
// 				}
// 			}
// 			dd{
// 				font-size:15px;
// 				color:#a1a3a6;
// 				padding:5px 0 5px 120px;
// 				p{
// 					font-size:15px;
// 					color:#a1a3a6;
// 				}
// 				&.last{
// 					padding:10px 0 10px 140px;
// 					p{
// 						font-size:24px;
// 						line-height:34px;
// 						color: #0064ff;;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.credit{
//         display: flex;
//         padding:14px 20px;
//         align-items:center;
//         justify-content: space-between;
//         em{
//             @include TMOBI500;
//             display: inline-block;
//             font-size: 16px;
//             color:#111;
//             position:relative;
//             padding-left:28px;
//             i{
//                 position:absolute;
//                 left:0;
//                 top:-2px;
//                 img{display: block;}
//             }
//         }
//         strong{
//             display: inline-block;
//             font-size: 24px;
//             @include TMOBI500;
//             color: #0064ff;
//         }
//     }
// }

// header{
// 	position:fixed;
// 	top:0;
// 	left:0;
// 	width:100%;
// 	background: #fff;
// 	z-index: 100;
// 	.header-top{
// 		height: 56px;
// 		position: relative;
// 		.header-title{
// 			text-align: center;
// 			@include TMOBI500;
// 			font-size:18px;
// 			color:#111;
// 			line-height:56px;
// 		}
// 		// .button-back{
// 		// 	position:absolute;
// 		// 	font-size:0;
// 		// 	top:-10px;
// 		// 	left:20px;
// 		// 	display: inline-block;
// 		// 	width:11px;
// 		// 	height:20px;
// 		// 	background: url(../../assets/images/history_back.png) no-repeat;
// 		// }

// 		.button-back-ani{
// 			position:relative;			
// 			width:8px;
// 			height:16px;
// 		}
// 	}
// 	.tab-menu{
// 		display: flex;
// 		a{
// 			@include TMOBI300;
// 			position:relative;
// 			display: inline-block;
// 			flex:1;
// 			line-height: 56px;
// 			color:#111;
// 			font-size:16px;
// 			border-bottom:1px solid #d7d7d7;
// 			text-align: center;
// 			&:before{
// 				content: "";
// 				display: none;
// 				width:100%;
// 				height: 3px;
// 				background: #0064ff;
// 				position: absolute;
// 				bottom:-1px;
// 				left:0;
// 			}
// 			&.active{
// 				color:#0064ff;
// 				pointer-events: none;

// 				&:before{
// 					display: block;
// 				}
// 			}
// 		}
// 	}

// 	.tab-menu{
// 		display: flex;
// 		ul {
// 			a {
// 				@include TMOBI300;
// 				position:relative;
// 				display: inline-block;
// 				flex:1;
// 				line-height: 56px;
// 				color:#111;
// 				font-size:16px;
// 				border-bottom:1px solid #d7d7d7;
// 				text-align: center;
// 				&:before{
// 					content: "";
// 					display: none;
// 					width:100%;
// 					height: 3px;
// 					background: #0064ff;
// 					position: absolute;
// 					bottom:-1px;
// 					left:0;
// 				}
// 				&.active{
// 					color:#0064ff;
// 					pointer-events: none;
// 					&:before{
// 						display: block;
// 					}
// 				}
// 			}
// 		}
// 	}


// }
// .charge-main{
// 	padding-top:113px;
// 	.inner-box{
// 		position: relative;
// 		padding:20px 20px 100px;
// 		overflow-y: auto;
// 		.hgroup{
// 			position:relative;
// 			h3{
// 				font-size:24px;
// 				line-height:34px;
// 				@include TMOBI300;
// 				color:#111;
// 				span{
// 					position: relative;
// 					display: inline-block;
// 					&:before{
// 						content: "";
// 						position:absolute;
// 						left:0;
// 						bottom:0;
// 						display: inline-block;
// 						width:100%;
// 						height: 10px;;
// 						background: #97d3ff;
// 						z-index: -1;
// 					}
// 				}
// 			}
// 			a{
// 				@include TMOBI300;
// 				display: inline-block;
// 				font-size:13px;
// 				color:#909090;
// 				position:absolute;
// 				top:10px;
// 				right:0;
// 				padding-left:20px;
// 				&:before{
// 					content: "";
// 					position: absolute;
// 					top:1px;
// 					left:0;
// 					width:16px;
// 					height:16px;
// 					display: inline-block;
// 					background: url(../../assets/images/ic-info.svg) no-repeat;
// 					background-size: cover;
// 				}
// 			}
// 		}
// 		.swiper{
// 			margin-top:30px;
// 			max-width:200px;
// 			.swiper-pagination{
// 				position:relative;
// 				bottom:inherit;
// 				margin-top:20px;
// 			}
// 		}
// 		.link{
// 			margin-top:20px;
// 			text-align: center;
// 			a{
// 				display:inline-block;
// 				width:100%;
// 				max-width:200px;
// 				line-height:40px;
// 				border:1px solid #accafb;
// 				border-radius: 20px;
// 				text-align: center;
// 				font-size:16px;
// 				color:#3673ee;
// 				@include TMOBI300;
// 			}
// 		}
// 		dl{
// 			margin-top:24px;
// 			dt{
// 				padding:15px 0 0;
// 				font-size:16px;
// 				@include TMOBI300;
// 				color:#111;
// 			}
// 			dd{
// 				padding:15px 0 15px 140px;
// 				border-bottom: 1px solid #f1f1f1;
// 				font-size:16px;
// 				@include TMOBI500;
// 				text-align: right;
// 				button{
// 					position: relative;
// 					display: inline-block;
// 					color:#e55545;
// 					font-size:16px;
// 					@include TMOBI500;
// 					padding-right:20px;
// 					max-width:100%;
// 					overflow: hidden;
// 					white-space: nowrap;
// 					text-overflow: ellipsis;
// 					&:after{
// 						content: "";
// 						display: inline-block;
// 						position:absolute;
// 						top:3px;
// 						right:0;
// 						width:16px;
// 						height: 16px;
// 						background: url(../../assets/images/icon_common_arrow_20.png) no-repeat;
// 						background-size: cover;
// 					}
// 				}
// 				&.selected{
// 					color:#111;
// 					button{
// 						color:#111;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.button-box{
// 		position: fixed;
// 		left:0;
// 		bottom:0;
// 		padding:20px;
// 		width:100%;
// 		background: rgba(255,255,255,0.7);
// 		a{
// 			display: block;
// 			text-align: center;
// 			font-size:17px;
// 			@include TMOBI300;
// 			line-height: 50px;
// 			color:#fff;
// 			background: #0064ff;
// 			border-radius:5px;
// 		}
// 	}
// }
// 		.info-list{
// 			margin-top:20px;
// 			padding:0 20px;
// 			li{
// 				position:relative;
// 				font-size:13px;
// 				color:#a1a3a6;
// 				@include TMOBI300;
// 				line-height: 20px;
// 				padding-left:10px;
// 				&:before{
// 					content: "";
// 					display: inline-block;
// 					width:2px;
// 					height: 2px;
// 					background:#a1a3a6;
// 					border-radius: 100%;
// 					position: absolute;
// 					left: 0;
// 					top: 9px;
// 				}
// 			}

// 		}

// 	}
// 	&.complete{
// 		.inner-box{

// 		}
// 	}

// 	.button-box{
// 		position: fixed;
// 		left:0;
// 		bottom:0;
// 		padding:20px;
// 		width:100%;
// 		background: -moz-linear-gradient(top,  rgba(255,255,255,0.7) 0%, rgba(255,255,255,1) 100%);
//         background: -webkit-linear-gradient(top,  rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 100%);
//         background: linear-gradient(to bottom,  rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 100%);		
// 		a{
// 			display: block;
// 			text-align: center;
// 			font-size:17px;
// 			@include TMOBI300;
// 			line-height: 50px;
// 			color:#fff;
// 			background: #0064ff;
// 			border-radius:5px;
// 		}
// 	}

// }

// .pay-contents{
// 	padding-top:113px;
// 	.inner-box{
// 		padding-bottom:100px;
// 		position:relative;
// 		.place-info{
// 			padding:24px 20px 0;
// 			.time-info{
// 				font-size:16px;
// 				color:#111;
// 				@include TMOBI300;
// 			}
// 			.addr-txt{
// 				margin-top:24px;
// 				position: relative;
// 				font-size:16px;
// 				padding-left:24px;
// 				@include TMOBI500;
// 				color: #111;
// 				line-height: 24px;
// 				text-align: left;

// 				&:before{
// 					content: "";
// 					position:absolute;
// 					top:-2px;
// 					left:0;
// 					display: inline-block;
// 					width:24px;
// 					height: 24px;
// 					background: url(../../assets/images/ic_map_evstation_blue.svg) no-repeat;
// 					background-size: cover;
// 				}
// 				span{
// 					display: block;
// 					font-size:13px;
// 					line-height:20px;
// 					color:#909090;
// 				}
// 			}
// 			.map-box{
// 				margin-top:16px;
// 				height: 110px;
// 				border-radius:10px;
// 				overflow: hidden;
// 			}
// 		}
// 		.pay-infobox{
// 			margin-top:0;
// 			dl{
// 				&.total-box{
// 					border-top:0;
// 					dt{
// 						font-size: 16px;
// 						color: #111;
// 						&.last{
// 							font-size: 18px;
// 						}
// 					}
// 					dd{
// 						font-size: 16px;
// 						color: #111;
// 						p{
// 							font-size: 16px;
// 							color: #111;
// 						}
// 						&.last{
// 							p{
// 								font-size:24px;
// 							}
// 						}
// 					}
// 				}
// 				&.payment{

// 					dt{
// 						width:140px;
// 						padding:15px 0 0;
// 						font-size:16px;
// 						@include TMOBI300;
// 						color:#111;
// 					}
// 					dd{
// 						padding:15px 0 15px 140px;
// 						border-bottom: 1px solid #f1f1f1;
// 						font-size:16px;
// 						@include TMOBI500;
// 						text-align: right;
// 						button{
// 							position: relative;
// 							display: inline-block;
// 							color:#e55545;
// 							font-size:16px;
// 							@include TMOBI500;
// 							padding-right:20px;
// 							max-width:100%;
// 							overflow: hidden;
// 							white-space: nowrap;
// 							text-overflow: ellipsis;
// 							&:after{
// 								content: "";
// 								display: inline-block;
// 								position:absolute;
// 								top:3px;
// 								right:0;
// 								width:16px;
// 								height: 16px;
// 								background: url(../../assets/images/icon_common_arrow_20.png) no-repeat;
// 								background-size: cover;
// 							}
// 						}
// 						&.selected{
// 							color:#111;
// 							button{
// 								color:#111;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.button-box{
// 		position: fixed;
// 		left:0;
// 		bottom:0;
// 		padding:20px;
// 		width:100%;
// 		background: rgba(255,255,255,0.7);
// 		.button{
// 			display: block;
// 			width:100%;
// 			text-align: center;
// 			font-size:17px;
// 			@include TMOBI300;
// 			line-height: 50px;
// 			color:#fff;
// 			background: #0064ff;
// 			border-radius:5px;
// 		}
// 	}

// }

// .charge-history{
// 	padding-top:113px;
// 	padding-bottom:40px;
// 	&.view{
// 		padding-top:53px;
// 	}
// 	.top{
// 		padding:24px 20px 20px;
// 		.hgroup{
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			strong{
// 				display: inline-block;
// 				font-size:16px;
// 				color:#0064ff;
// 				line-height:24px;
// 				@include TMOBI500;
// 			}
// 			.sort-button{
// 				display:inline-block;
// 				width:28px;
// 				height:28px;
// 				background: url(../../assets/images/ic-sort.svg) no-repeat;
// 				background-size: cover;
// 				font-size:0;
// 			}
// 		}
// 		.total-box{
// 			margin-top:12px;
// 			overflow: hidden;
// 			background:#f5f6fa;
// 			border-radius:8px;
// 			figure{
// 				img{
// 					display: block;
// 					width:100%;
// 				}
// 			}
// 			.info-box{
// 				display: flex;
// 				align-items: center;
// 				li{
// 					padding:16px 0;
// 					flex:1;
// 					text-align: center;
// 					span{
// 						display: block;
// 						font-size:12px;
// 						line-height: 18px;
// 						color:#a1a3a6;
// 						@include TMOBI300;
// 					}
// 					strong{
// 						display: block;
// 						@include TMOBI500;
// 						font-size:15px;
// 						color:#111;
// 						line-height:22px;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.list-box{
// 		margin-top:16px;
// 		.list{
// 			position:relative;
// 			padding-top:16px;
// 			padding-left: 20px;
// 			border-top:1px solid #f1f1f1;
// 			h4{
// 				font-size:13px;
// 				color:#666;
// 				@include TMOBI300;
// 				padding:0 10px 0 20px;
// 				display: inline-block;
// 				background: #fff;
// 				position: absolute;
// 				top:-9px;
// 				left:0;
// 			}
// 			ul{

// 				li{
// 					border-top:1px solid #f1f1f1;
// 					padding:16px 20px 16px 0;
// 					&:first-child{
// 						margin-top:0;
// 						border-top:0;
// 					}
// 					a{
// 						display: block;
// 						position:relative;
// 						padding-left:40px;
// 						.time{
// 							position:absolute;
// 							font-size:13px;
// 							color:#909090;
// 							left:0;
// 							@include TMOBI300;
// 						}
// 						.addr-txt{
// 							position: relative;
// 							font-size: 16px;
// 							padding-left: 24px;
// 							@include TMOBI500;
// 							color: #111;
// 							line-height: 24px;
// 							text-align: left;
// 							overflow: hidden;
// 							text-overflow: ellipsis;
// 							white-space: nowrap;
// 							em{
// 								display: block;
// 								font-size: 13px;
// 								line-height: 20px;
// 								color: #909090;
// 								overflow: hidden;
// 								text-overflow: ellipsis;
// 								white-space: nowrap;
// 								@include TMOBI300;
// 							}
// 							&:before{
// 								content: "";
// 								position: absolute;
// 								top: -2px;
// 								left: 0;
// 								display: inline-block;
// 								width: 24px;
// 								height: 24px;
// 								background: url(../../assets/images/ic_map_evstation_blue.svg) no-repeat;
// 								background-size: cover;
// 							}
// 						}
// 						.txt{
// 							margin-top:8px;
// 							padding-left:24px;
// 							display: flex;
// 							justify-content: space-between;
// 							align-items: center;
// 							span{
// 								position: relative;
// 								font-size: 16px;
// 								color:#111;
// 								line-height: 24px;
// 								@include TMOBI500;
// 								&.price{
// 									color:#0064ff;
// 									padding-right:17px;
// 									margin-left:10px;
// 									&:after{
// 										content: "";
// 										position:absolute;
// 										top:4px;
// 										right:0;
// 										display: inline-block;
// 										width:16px;
// 										height: 16px;
// 										background: url(../../assets/images/icon-common-arrow-20.png) no-repeat;
// 										background-size: cover;
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.nodata{
// 		height: calc(100vh - 153px);
// 		position: relative;
// 		padding:20px;
// 		figure{
// 			position: absolute;
// 			top:50%;
// 			left:50%;
// 			transform: translate(-50%, -50%);
// 			figcaption{
// 				display: block;
// 				margin-top:16px;
// 				text-align: center;
// 				font-size:15px;
// 				line-height: 22px;
// 				color:#a0a4a6;
// 				@include TMOBI300;
// 			}
// 		}
// 	}
// 	.detail{
// 		.inner-box{
// 			padding-bottom:0;
// 			position:relative;
// 			.place-info{
// 				padding:24px 20px 0;
// 				.time-info{
// 					font-size:16px;
// 					color:#111;
// 					@include TMOBI300;
// 				}
// 				.addr-txt{
// 					margin-top:24px;
// 					position: relative;
// 					font-size:16px;
// 					padding-left:24px;
// 					@include TMOBI500;
// 					color: #111;
// 					line-height: 24px;
// 					text-align: left;

// 					&:before{
// 						content: "";
// 						position:absolute;
// 						top:-2px;
// 						left:0;
// 						display: inline-block;
// 						width:24px;
// 						height: 24px;
// 						background: url(../../assets/images/ic_map_evstation_blue.svg) no-repeat;
// 						background-size: cover;
// 					}
// 					span{
// 						display: block;
// 						font-size:13px;
// 						line-height:20px;
// 						color:#909090;
// 					}
// 				}
// 				.map-box{
// 					margin-top:16px;
// 					height: 110px;
// 					border-radius:10px;
// 					overflow: hidden;
// 				}
// 			}
// 			.pay-infobox{
// 				margin-top:0;
// 				dl{
// 					&.total-box{
// 						border-top:0;
// 						dt{
// 							font-size: 16px;
// 							color: #111;
// 						}
// 						dd{
// 							font-size: 16px;
// 							color: #111;
// 							p{
// 								font-size: 16px;
// 								color: #111;
// 							}

// 						}
// 					}
// 					&.payment{

// 						dt{
// 							width:140px;
// 							padding:15px 0 0;
// 							font-size:16px;
// 							@include TMOBI300;
// 							color:#111;
// 						}
// 						dd{
// 							padding:15px 0 15px 140px;
// 							border-bottom: 1px solid #f1f1f1;
// 							font-size:16px;
// 							@include TMOBI500;
// 							text-align: right;
// 							button{
// 								position: relative;
// 								display: inline-block;
// 								color:#e55545;
// 								font-size:16px;
// 								@include TMOBI500;
// 								padding-right:20px;
// 								max-width:100%;
// 								overflow: hidden;
// 								white-space: nowrap;
// 								text-overflow: ellipsis;
// 								&:after{
// 									content: "";
// 									display: inline-block;
// 									position:absolute;
// 									top:3px;
// 									right:0;
// 									width:16px;
// 									height: 16px;
// 									background: url(../../assets/images/icon_common_arrow_20.png) no-repeat;
// 									background-size: cover;
// 								}
// 							}
// 							&.selected{
// 								color:#111;
// 								button{
// 									color:#111;
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

#page {
    padding-top: 56px;
    height: 100vh;
    // .qr-chager{
    // 	padding:20px;
    // 	height: 100%;
    // 	background: url(../../assets/images/img_ev_charger.svg) center bottom no-repeat;
    // 	background-size: 100%;
    // 	.txt-box{
    // 		margin-top:37px;
    // 		.txt1{
    // 			font-size:24px;
    // 			@include TMOBI300;
    // 			line-height: 34px;
    // 			 display:-webkit-box;
    // 			word-wrap:break-word;
    // 			-webkit-line-clamp:3;
    // 			-webkit-box-orient:vertical;
    // 			overflow:hidden;
    // 			text-overflow:ellipsis;
    // 			max-height:110px;
    // 		}
    // 		.txt2{
    // 			font-size:24px;
    // 			@include TMOBI300;
    // 			line-height: 34px;
    // 			margin-top:16px;
    // 		}
    // 	}
    // }
    // .qr-chager2{
    // 	padding:20px;
    // 	height: 100%;
    // 	background: url(../../assets/images/img-connector.svg) center bottom no-repeat;
    // 	background-size: 100%;
    // 	.txt-box{
    // 		margin-top:37px;
    // 		.txt{
    // 			font-size:24px;
    // 			@include TMOBI300;
    // 			line-height: 34px;
    // 			margin-top:16px;
    // 		}
    // 	}
    // }
    // .qr-chager3{
    // 	padding:20px;
    // 	height: 100%;
    // 	background: url(../../assets/images/img-connector-car.svg) center bottom no-repeat;
    // 	background-size: 100%;
    // 	.txt-box{
    // 		margin-top:37px;
    // 		.txt{
    // 			font-size:24px;
    // 			@include TMOBI300;
    // 			line-height: 34px;
    // 			margin-top:16px;
    // 		}
    // 	}
    // }
    // .qr-chager4{
    // 	padding:20px;
    // 	height: 100%;
    // 	background: url(../../assets/images/img-connecting-car.svg) center bottom no-repeat;
    // 	background-size: 100%;
    // 	.txt-box{
    // 		margin-top:37px;
    // 		.txt{
    // 			font-size:24px;
    // 			@include TMOBI300;
    // 			line-height: 34px;
    // 			margin-top:16px;
    // 		}
    // 	}
    // }
}

// .charger-guide{
// 	padding: 20px;
//     height: 100%;
//     background: url(../../assets/images/img-info-evstastion.svg) center bottom no-repeat;
//     background-size: 100%;
//     h3{
//     	font-size: 24px;
//     	@include TMOBI300;
//     	line-height: 34px;
//     }
//     ul{
//     	margin-top:32px;
//     	li{
//     		margin-top:14px;
//     		position: relative;
//     		padding-left:26px;
//     		font-size:13px;
//     		color:#666;
//     		line-height:20px;
//     		@include TMOBI300;
//     		&:first-child{
//     			margin-top:0;
//     		}
//     		&:before{
//     			content: "";
//     			position:absolute;
//     			top:1px;
//     			left:0;
//     			display: inline-block;
//     			text-align: center;
//     			width:18px;
//     			height: 18px;
//     			line-height: 17px;
//     			font-size:11px;
//     			color:#fff;
//     			border-radius:100%;
//     			background: #0064ff;
//     		}
//     		&:nth-child(1):before{
//     			content: "1";
//     		}
//     		&:nth-child(2):before{
//     			content: "2";
//     		}
//     		&:nth-child(3):before{
//     			content: "3";
//     		}
//     		&:nth-child(4):before{
//     			content: "4";
//     		}
//     	}
//     }

// }

// .charge-popup{
// 	position: fixed;
// 	top:0;
// 	left:0;
// 	width:100%;
// 	height: 100%;
// 	background: rgba(0,0,0,0.7);
// 	z-index:10000;
// 	.popup-wrap{
// 		position:absolute;
// 		top:50%;
// 		left:50%;
// 		width:310px;
// 		transform: translate(-50%, -50%);
// 		border-radius: 10px;
// 		overflow: hidden;
// 		.charge-popcontents{
// 			background:#fff;
// 			padding:35px 0;
// 			figure{
// 				text-align: center;
// 			}
// 			.txt-box{
// 				text-align: center;
// 				margin-top:15px;
// 				h3{
// 					font-size:18px;
// 					@include TMOBI300;
// 					color:#111;
// 					line-height: 26px;;
// 				}
// 				p{
// 					@include TMOBI300;
// 					margin-top:15px;
// 					line-height:22px;
// 					color:#a1a3a6;
// 					font-size:15px;
// 				}
// 			}
// 		}
// 		.charge-popbottom{
// 			.button-group{
// 				display: flex;
// 				button{
// 					border:0;
// 					padding:0;
// 					margin:0;
// 					flex:1;
// 					text-align: center;
// 					background:#f0f0f0;
// 					color:#111;
// 					font-size:16px;
// 					line-height:52px;
// 					@include TMOBI300;
// 					&:last-child{
// 						background: #0064ff;
// 						color:#fff;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .t-popup{
// 	position: fixed;
// 	top:0;
// 	left:0;
// 	width:100%;
// 	height: 100%;
// 	background: rgba(0,0,0,0.7);
// 	z-index:10000;
// 	.t-popupwrap{
// 		position:absolute;
// 		top:50%;
// 		left:50%;
// 		width:310px;
// 		transform: translate(-50%, -50%);
// 		border-radius: 10px;
// 		overflow: hidden;
// 		.t-popupcon{
// 			background:#fff;
// 			padding:35px 0;
// 			h3{
// 				font-size:18px;
// 				@include TMOBI300;
// 				color:#111;
// 				line-height: 26px;
// 				text-align: center;
// 			}
// 			p{
// 				margin-top:15px;
// 				font-size:15px;
// 				text-align: center;
// 				color:#a1a3a6;
// 				line-height: 22px;;
// 			}
// 		}
// 		.t-popup-bottom{

// 			button{
// 				border:0;
// 				padding:0;
// 				margin:0;
// 				display: block;
// 				width:100%;
// 				text-align: center;
// 				background:#f0f0f0;
// 				color:#111;
// 				font-size:16px;
// 				line-height:52px;
// 				@include TMOBI300;
// 				&:last-child{
// 					background: #0064ff;
// 					color:#fff;
// 				}
// 			}
// 		}
// 	}
// }

// .bottom-popup{
// 	position: fixed;
// 	top:0;
// 	left:0;
// 	width:100%;
// 	height: 100%;
// 	background: rgba(0,0,0,0.7);
// 	z-index:10000;
// 	.popup-wrap{
// 		position: absolute;
// 		left:0;
// 		bottom:0;
// 		width:100%;
// 		background: #fff;
// 		padding:25px 20px 20px;
// 		border-top-left-radius: 10px;
// 		border-top-right-radius: 10px;
// 		.popup-contents{
// 			p{	
// 				// left:50%;
// 			 	// text-align: center;
// 				@include TMOBI300;
// 				font-size: 18px;
// 				color:#000;
// 			}
// 			span{
// 				// left:50%;
// 				// text-align: center;
// 				font-family : 'TMOBI300';
// 				font-size: 16px;
// 				color:#a1a3a6;
// 			}
// 		}
// 		.popup-bottom{
// 			margin-top:25px;
// 			display: flex;
// 			button{
// 				flex:1;
// 				line-height:52px;
// 				background: #f0f0f0;
// 				border-radius: 10px;
// 				font-size:17px;
// 				color:#111;
// 				display: inline-block;
// 				text-align: center;
// 				& + button{
// 					color:#fff;
// 					background:#0064ff;
// 					margin-left:8px;
// 				}
// 			}
// 		}
// 	}
// }

// .coupon-contents{
// 	padding-top:113px;
// 	padding-bottom:90px;
// 	.coupon-section{
// 		padding:24px 20px;
// 		background: #fff;
// 		h3{
// 			font-size:16px;
// 			line-height: 24px;
// 			color:#111;
// 			@include TMOBI500;
// 		}
// 		ul{
// 			margin-top:24px;
// 			li{
// 				overflow: hidden;
// 				border-radius: 5px;;
// 				margin-top:16px;
// 				position:relative;
// 				box-shadow: 0 4px 10px 0 rgba(47, 54, 64, 0.4);
// 				button{
// 					display: block;
// 					width:100%;
// 					height: 100%;
// 				}
// 				img{
// 					display: block;
// 					width:100%;
// 				}
// 				figure{

// 				}
// 				&:first-child{
// 					margin-top:0;
// 				}
// 				.text-box{
// 					position: absolute;
// 					top:16px;
// 					left:16px;
// 					h4{
// 						font-size:18px;
// 						color:#fff;
// 						line-height:26px;
// 						@include TMOBI500;
// 						em{
// 							display: inline-block;
// 						}
// 					}
// 					p{
// 						margin-top:4px;
// 						position: relative;
// 						font-size:24px;
// 						line-height: 34px;
// 						color:#fff;
// 						font-family: 'TMOBI700';
// 					}
// 					span{
// 						font-size:12px;
// 						color:#fff;
// 						line-height:20px;
// 					}
// 				}
// 				.checkbox{
// 					position: absolute;
// 					top:16px;
// 					right:16px;
// 					z-index: 10;
// 					input[type="checkbox"]{
// 						position: absolute;
// 						opacity: 0;
// 						&:checked{
// 							& + label{
// 								background: url(../../assets/images/component-check-box-card-on.png) no-repeat;
// 							}
// 						}
// 					}
// 					label{
// 						display: block;
// 						width:22px;
// 						height: 22px;
// 						background: url(../../assets/images/component-check-box-card-off.png) no-repeat;
// 						background-size: cover;
// 					}

// 				}

// 			}
// 		}
// 		.button-box{
// 			margin-top: 24px;
// 			button{
// 				position: relative;
// 				display: block;
// 				width:100%;
// 				@include TMOBI300;
// 				line-height:56px;
// 				font-size:16px;
// 				color:#a1a3a6;
// 				&:before{
// 					content: "";
// 					position: relative;
// 					top:-1px;
// 					margin-right:10px;
// 					display: inline-block;
// 					width:18px;
// 					height: 18px;
// 					background: url(../../assets/images/ic_add_g.svg) no-repeat;
// 					background-size: cover;
// 					vertical-align: middle;
// 				}
// 			}
// 		}
// 		& + .coupon-section{
// 			border-top:8px solid #f5f5f5;
// 		}
// 	}
// 	.button-group{
// 		position: fixed;
// 		left: 0;
// 		bottom: 0;
// 		padding: 20px;
// 		width: 100%;
// 		background: rgba(255, 255, 255, 0.7);
// 		z-index:50;
// 		button{
// 			display: block;
// 			width:100%;
// 			text-align: center;
// 			font-size: 17px;
// 			@include TMOBI300;
// 			line-height: 50px;
// 			color: #fff;
// 			background: #0064ff;
// 			border-radius: 5px;
// 			&:disabled{
// 				background: #f0f0f0;
// 				color:#dcdcdc;
// 			}
// 		}
// 	}
// }

// .coupon-detail{
// 	padding-top:53px;
// 	padding-bottom:20px;
// 	.info-box{
// 		padding:24px 20px;
// 		h3{
// 			font-size:18px;
// 			line-height:26px;
// 			color:#111;
// 			@include TMOBI500;
// 		}
// 		p{
// 			margin-top:8px;
// 			display: flex;
// 			justify-content: space-between;
// 			align-items:flex-end;
// 			em{
// 				position:relative;
// 				display: inline-block;
// 				font-size:24px;
// 				line-height:34px;
// 				color:#111;
// 				@include TMOBI500;
// 				padding-left:30px;
// 				&:before{
// 					content: "";
// 					display: inline-block;
// 					width:24px;
// 					height: 24px;
// 					background: url(../../assets/images/ic-p-black.svg) no-repeat;
// 					background-size: cover;
// 					position: absolute;
// 					left: 0;
// 					top: 6px;
// 				}
// 			}
// 			span{
// 				display: inline-block;
// 				font-size:13px;
// 				line-height:20px;
// 				color:#a1a3a6;
// 				@include TMOBI300;
// 			}
// 		}
// 	}
// 	.list-box{
// 		.list{
// 			position: relative;
// 			margin-top:24px;
// 			padding-top: 16px;
// 			padding-left: 20px;
// 			border-top: 1px solid #f1f1f1;
// 			h4{
// 				font-size: 13px;
// 				color: #666;
// 				@include TMOBI300;
// 				padding: 0 10px 0 20px;
// 				display: inline-block;
// 				background: #fff;
// 				position: absolute;
// 				top: -9px;
// 				left: 0;
// 			}
// 			ul{
// 				li{
// 					padding:16px 20px 16px 0;
// 					display: flex;
// 					justify-content: space-between;
// 					align-items: center;
// 					font-size:16px;
// 					@include TMOBI500;
// 					em{
// 						display: inline-block;
// 						font-size:12px;
// 						color:#909090;
// 						@include TMOBI300;
// 						margin-right:15px;
// 					}
// 					.name{
// 						color: #111;
// 						overflow: hidden;
// 						text-overflow: ellipsis;
// 						white-space: nowrap;
// 						width: calc(100% - 130px);
// 						text-align: left;
// 						margin-right: 15px;
// 					}
// 					.price{
// 						&.deduction{
// 							color:#e55545
// 						}
// 						&.add{
// 							color:#0064ff;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .coupon-register{
// 	position: relative;
// 	padding:53px 20px 20px;
// 	.register-box{
// 		margin-top:36px;
// 		fieldset{
// 			figure{
// 				overflow: hidden;
// 				border-radius:5px;
// 				img{
// 					display: block;
// 					width:100%;
// 				}
// 			}
// 			.field{
// 				position: relative;
// 				margin-top:24px;
// 				padding:0 20px;
// 				input[type="text"]{
// 					width:100%;
// 					height: 54px;
// 					font-size:18px;
// 					@include TMOBI300;
// 					border-bottom:1px solid #0065ff;
// 					opacity: 0.5;
// 					&::placeholder{
// 						color:#dbdbdb;
// 					}
// 					&:focus{
// 						outline: 0;
// 						opacity: 1;
// 					}
// 				}
// 				button{
// 					position:absolute;
// 					top:20px;
// 					right:20px;
// 					width:18px;
// 					height: 18px;
// 					font-size:0;
// 					&.add-button{
// 						background: url(../../assets/images/ic_add_b.svg) no-repeat;
// 						background-size: cover;
// 					}
// 					&.reset-button{
// 						background: url(../../assets/images/ic_delete.svg) no-repeat;
// 						background-size: cover;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.button-box{
// 		position: fixed;
// 		left: 0;
// 		bottom: 0;
// 		padding: 20px;
// 		width: 100%;
// 		background: rgba(255, 255, 255, 0.7);
// 		button{
// 			display: block;
// 			width:100%;
// 			text-align: center;
// 			font-size: 17px;
// 			@include TMOBI300;
// 			line-height: 50px;
// 			color: #fff;
// 			background: #0064ff;
// 			border-radius: 5px;
// 			&:disabled{
// 				background: #f0f0f0;
// 				color:#dcdcdc;
// 			}
// 		}
// 	}
// }

// @keyframes load{0%{stroke-dashoffset:0}}
// .progress {
// 	position: relative;
// 	display: inline-block;
// 	padding: 0;
// 	text-align: center;
// 	& > div {
// 		display: inline-block;
// 		width:210px;
// 		height:210px;
// 		position: relative;
// 		text-align: center;
// 		color: #111;
// 		@include TMOBI300;
// 		font-size:56px;

// 		.inner-txt {
// 			position: absolute;
// 			width: 100%;
// 			top: 50%;
// 			transform:translateY(-50%);
// 			left: 0;
// 			text-align: center;
// 			font-size:0;
// 			i{
// 				margin:0 auto;
// 				display:block;
// 				width:24px;
// 				height:24px;
// 				background: url(../../assets/images/ic-electricity.svg) no-repeat;
// 				background-size: cover;
// 			}
// 			p{
// 				font-size:0;
// 				em{
// 					display: inline-block;
// 					color:#111;
// 					font-size: 56px;
// 					@include TMOBI300;
// 				}
// 				&:after{
// 					content: "%";
// 					display: inline-block;
// 					font-size:16px;
// 					color:#111;
// 					@include TMOBI300;
// 				}
// 			}
// 			span{
// 				display:block;
// 				font-size:13px;
// 				color:#111;
// 			}
// 		}
// 		.inner-txt2 {
// 			position: absolute;
// 			width: 100%;
// 			top: 50%;
// 			transform:translateY(-50%);
// 			left: 0;
// 			text-align: center;
// 			font-size:0;
// 			i{
// 				margin:0 auto;
// 				display:block;
// 				width:24px;
// 				height:24px;
// 				background: url(../../assets/images/ic-electricity.svg) no-repeat;
// 				background-size: cover;
// 			}
// 			p{
// 				font-size:0;
// 				span{
// 					display: inline-block;
// 					color:#111;
// 					font-size: 48px;
// 					@include TMOBI300;
// 					display: inline-block;
// 					letter-spacing: -4px;
// 					&:after{
// 						content: "";
// 						display: inline-block;
// 						font-size:10px;
// 						color:#111;
// 						@include TMOBI300;
// 					}
// 					&.hour:after{
// 						content: "시";
// 					}
// 					&:nth-child(2){
// 						margin-left:10px;
// 					}
// 					&.minute:after{
// 						content: "분";
// 					}
// 				}
// 				em{
// 					display: inline-block;
// 				}

// 			}
// 			span{
// 				margin-top:5px;
// 				display:block;
// 				font-size:13px;
// 				color:#111;
// 			}
// 		}
// 		.inner-txt3 {
// 			position: absolute;
// 			width: 100%;
// 			top: 50%;
// 			transform:translateY(-50%);
// 			left: 0;
// 			text-align: center;
// 			font-size:0;
// 			i{
// 				margin:0 auto;
// 				display:block;
// 				width:24px;
// 				height:24px;
// 				background: url(../../assets/images/ic-checked.svg) no-repeat;
// 				background-size: cover;
// 			}
// 			p{
// 				font-size:0;
// 				span{
// 					display: inline-block;
// 					color:#111;
// 					font-size: 36px;
// 					@include TMOBI300;
// 					display: inline-block;
// 					letter-spacing: -4px;

// 				}
// 				em{
// 					display: inline-block;
// 				}

// 			}
// 			span{
// 				margin-top:10px;
// 				display:block;
// 				font-size:13px;
// 				color:#111;
// 			}
// 		}
// 	}
// 	svg {
// 		width:210px;
// 		height:210px;
// 		&:nth-child(2) {
// 			position: absolute;
// 			left: 0;
// 			top: 0;
// 			transform: rotate(-90deg);
// 		}
// 		&:nth-child(2) path {
// 			-moz-transition: all 0.5s;
// 			-webkit-transition: all 0.5s;
// 			-ms-transition: all 0.5s;
// 			-o-transition: all 0.5s;
// 			transition: all 0.5s;
// 			fill: none;
// 			stroke-width: 25;
// 			stroke-dasharray: 629;
// 			stroke: #fff;
// 			opacity:.9;
// 			animation: load 10s;
// 		}
// 	}
// }



/* evDelivery */
.term-top {
    padding: 17px 19px;
    height: 56px;
    text-align: right;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 999;

    button {
        position: absolute;
        top: 7px;
        right: 8px;
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(../../assets/images/common/ic-top-close.svg) no-repeat;
        background-size: cover;
        font-size: 0;
    }
}

.term-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 70%);
    width: 100%;
}

.terms-box {
    padding-top: 56px;

    .title-box {
        padding: 5px 20px 15px;

        h3 {
            @include TMOBI500;
            font-size: 24px;
            line-height: 1.44;
            color: #111;
            letter-spacing: normal;
        }
    }

    .chk-wrap {
        padding: 16px 20px 90px;

        .allchk {
            border-radius: 8px;
            border: solid 1px #c6cad2;
            background-color: #fff;
            padding: 15px 20px;

            .checkbox-type1 {
                label {
                    @include TMOBI500;
                    font-size: 16px;
                    line-height: 1.47;
                    letter-spacing: normal;
                    color: #111;
                }
            }
        }

        ul {
            li {
                position: relative;
                padding: 17px 16px 17px 44px;

                .check {
                    position: relative;

                    input[type="checkbox"] {
                        position: absolute;
                        opacity: 0;
                        ;

                        &:checked+label {
                            color: #111;

                            &:before {
                                border-color: #0064ff;
                            }
                        }
                    }

                    label {
                        position: relative;
                        @include TMOBI300;
                        font-size: 14px;
                        line-height: 1.47;
                        color: #111;

                        &:before {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            top: 2px;
                            left: -16px;
                            width: 6px;
                            height: 11px;
                            border-right: 2px solid #ccc;
                            border-bottom: 2px solid #ccc;
                            transform: rotate(45deg);
                        }
                    }
                }

                .txt {
                    @include TMOBI300;
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #8C9299;
                }

                dl {
                    margin-top: 16px;
                    border-top: 1px solid #f1f1f1;

                    dt {
                        @include TMOBI300;
                        width: 110px;
                        font-size: 11px;
                        color: #a1a3a6;
                        padding-top: 12px;
                    }

                    dd {
                        border-bottom: 1px solid #f1f1f1;
                        padding: 12px 0 12px 110px;
                        font-size: 11px;
                        // color: #2b65d9;
                        word-break: keep-all;
                    }
                }
            }
        }
    }

    .button-box {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 20px;
        background: #fff;

        button {
            display: block;
            width: 100%;
            height: 50px;
            border-radius: 5px;
            background-color: #0064ff;
            text-align: center;
            color: #fff;
            font-size: 17px;
            @include TMOBI300;
        }
    }

    .dim-button-box {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 20px;
        width: 100%;
        background: #fff;
        z-index: 9;

        button {
            display: block;
            width: 100%;
            line-height: 50px;
            border-radius: 5px;
            text-align: center;
            color: #fff;
            height: 50px;
            background: #979797;
            font-size: 17px;
            @include TMOBI300;
        }
    }
}

//메인
.charge-map {
    .search {
        position: fixed;
        padding: 10px 20px 12px 10px;
        // margin-top : 10px;
        // background-color: #fff;
        display: flex;
        overflow: hidden;
        // width: 100%;
        // height: 120px;

        // &:before {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     bottom: -7px;
        //     width: 100%;
        //     height: 7px;
        //     background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 70%);
        // }

        i {
            position: absolute;
            top: 3px;
            left: 15px;
            display: inline-block;
            width: 36px;
            height: 36px;
            background: url(../../assets/images/common/ic-searchbar-current-position.png) no-repeat;
            background-size: cover;
        }

        input[type="text"] {
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
            width: 100%;
            height: 47px;
            top:1.5px;
            border-radius: 26px;
            padding: 3px 24px 0;
            color: #111;
            background-color: #fff;
            position: relative;
            left: 8px;
            letter-spacing: normal;

            &::placeholder {
                @include TMOBI300;
                // line-height: 1.6;
                color: #BEC5CC;
                font-size: 17.5px;
                // letter-spacing: normal;
            }
        }

        // button {
        //     position: absolute;
        //     right: 20px;
        //     top: 2px;
        //     font-size: 0;
        //     width: 36px;
        //     height: 36px;
        //     // background: url(../../assets/images/ic-searchbar-search.svg) no-repeat;
        //     background-size: cover;
        // }

    }

    .delivery-button {
        width: fit-content;
        // text-align: center;
        height: fit-content;
        border-radius: 17px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
        background-color: #fff;
        position: absolute;
        right: 13px;
        top: 70px;

        span {
            display: inline-block;
            vertical-align: middle;
            @include TMOBI300;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            background: black;
            // color: #000;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 8px 12px 7px 8px;

            &:before {
                content: "";
                display: inline-block;
                // width: fit-content;
                // height: fit-content;
                width: 20px;
                height: 20px;
                background: url(../../assets/images/common/ic-delivery.svg) no-repeat;
                position: relative;
                // margin-right: 8px;
                vertical-align: middle;
            }
        }
    }

    .map-box {
        position: relative;

        // height:calc(100vh - 110px);
        .filter {
            position: fixed;
            top: 110px;
            padding: 12px 20px 0px 20px;
            width: 100%;
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            white-space: nowrap;

            &::-webkit-scrollbar {
                display: none;
            }

            .inner-wrap {
                width: 505px;
                padding-bottom: 12px;
                display: flex;
                align-items: center;

                // margin-right: 30px;
                button {
                    @include TMOBI300;
                    display: inline-block;
                    padding: 0 10px;
                    border-radius: 15px;
                    height: 30px;
                    text-align: center;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                    // background: url(../../assets/images/ic-filter-arrow-down-enabled.svg) no-repeat;                    
                    // background-size: 12px;
                    // background-position: right;
                    background-color: #0064ff;
                    font-size: 13px;
                    color: #fff;
                }

                .buttonN {
                    @include TMOBI300;
                    display: inline-block;
                    padding: 5px 25px 5px 10px;
                    border-radius: 15px;
                    height: 30px;
                    text-align: center;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                    background: url(../../assets/images/common/ic-filter-arrow-down-enabled.svg) no-repeat;
                    background-size: 10px;
                    background-position: 90% 50%;
                    background-color: #0064ff;
                    font-size: 13px;
                    color: #fff;
                }

                .buttonY {
                    @include TMOBI300;
                    display: inline-block;
                    padding: 5px 25px 5px 10px;
                    border-radius: 15px;
                    height: 30px;
                    text-align: center;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                    background: url(../../assets/images/common/ic-filter-arrow-down-selected.svg) no-repeat;
                    background-size: 10px;
                    background-position: 90% 50%;
                    background-color: #0064ff;
                    font-size: 13px;
                    color: #fff;
                }

                select {
                    margin-left: 5px;
                    @include TMOBI300;
                    display: inline-block;
                    height: 30px;
                    padding: 0px 24px 0 10px;
                    border-radius: 15px;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                    background: url(../../assets/images/common/ic-filter-arrow-down-selected.svg) no-repeat;
                    background-color: #0064ff;
                    // background-size:12px;
                    font-size: 13px;
                    color: #fff;
                }
            }
        }

        .in-map {
            height: 100%;
        }

        .charge-popup {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 1000;

            .popup-box {
                display: flex;
                position: fixed;
                flex-direction: column;
                width: 310px;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;

                .popup-wrap {
                    position: relative;
                    height: 340px;
                    // top: 50%;
                    // left: 50%;
                    // width: 310px;
                    // transform: translate(-50%, -50%);
                    border-top-right-radius: 10px;
                    overflow: hidden;
                    border-top-left-radius: 10px;

                    .charge-popcontents {

                        // background-color: #fff;
                        figure {
                            text-align: center;
                        }

                        .txt-box {
                            text-align: center;
                            margin-bottom: 18px;

                            h3 {
                                font-size: 18px;
                                @include TMOBI300;
                                color: #111;
                                line-height: 26px;
                            }

                            p {
                                @include TMOBI300;
                                margin-top: 15px;
                                line-height: 22px;
                                color: #a1a3a6;
                                font-size: 15px;
                            }
                        }
                    }

                    .charge-popbottom .button-group {

                        // margin-top: 100px;
                        button {
                            border: 0;
                            padding: 0;
                            margin: 0;
                            //flex: 1;
                            text-align: center;
                            background: #f0f0f0;
                            color: #111;
                            font-size: 16px;
                            line-height: 52px;
                            @include TMOBI300;
                        }
                    }

                    .popup-textbox {
                        display: flex;
                        justify-content: left;
                        // align-items: flex-end;
                        position: relative;
                        width: 100%;
                        height: 100%;


                        .popup-text {
                            position: absolute;
                            // top: 25%;
                            // left: 23%;
                            transform: translate(22%, -413%);
                            color: black;
                            font-Size: 23px;
                            text-Align: left;
                            @include TMOBI500;
                        }
                    }
                    
                }

                .checkbox-type1 {
                    position: relative;
                    // width: 310px;
                    // left: 50%;
                    // transform: translate(-50%, -50%);
                    // margin-top: 230px;
                    // padding-left: 20px;
                    background-color: #fff;
                    // top: 44.5%;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 15px;

                    .mainChkpopup {
                        font-size: 13px;
                        color: #a1a3a6;
                        @include TMOBI300;
                    }

                    .closePop {
                        color: #111;
                        float: right;
                        font-size: 13px;
                        text-align: center;
                        @include TMOBI300;
                    }
                }

            }
        }

    }

    .button-group {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 20px 20px;
        background: #fff;
        z-index: 1000;
        display: flex;
        &:before {
            content: "";
            position: absolute;
            top: -20px;
            left: 0;
            display: block;
            width: 100%;
            height: 20px;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
        }

        button {
            @include TMOBI300;
            position: relative;
            display: inline-block;
            width: 100%;
            height: 51.5px;
            border-radius: 8px;
            background-color: #EEF0F3;
            font-size: 18px;
            text-align: center;
            color: #111;

            i {
                display: inline-block;
                width: 24px;
                height: 24px;
                position: relative;
                background: url(../../assets/images/common/ic-qr-scanning-enabled.svg);
                background-size: cover;
                margin-right: 4px;
                vertical-align: middle;
            }

        }
    }
}

// .list-box{
//     // position:absolute;
//     left:0;
//     bottom:0;
//     width:100%;
//     background: #fff;
//     border-top-left-radius: 15px;
//     border-top-right-radius: 15px;
//     overflow: hidden;
//     z-index: 100;
//     padding-bottom:65px;
//     height:50%;
//     max-height: 90%;
//     overflow-y: auto;
//     ul{
//         padding:8px 20px;
//         li{
//             position:relative;
//             padding:14px 75px 14px 30px;
//             border-top:1px solid #f1f1f1;
//             &:before{
//                 content: "";
//                 display: inline-block;
//                 width:24px;
//                 height:24px;
//                 position:absolute;
//                 left:0;
//                 top:50%;
//                 transform: translateY(-50%);
//                 background: url(../../assets/images/ic-evstation-brand-01.svg) no-repeat;;
//             }
//             h4{
//                 @include TMOBI500;
//                 font-size: 15px;
//                 line-height: 1.6;
//                 color: #000;
//             }

.filter-top {
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
    height: 56px;
    position: relative;
    text-align: center;

    .filter-title {
        @include TMOBI500;
        font-size: 18px;
        color: #111;
        line-height: 56px;
    }
}

// .react-modal-sheet-content {
//     flex: auto;

.filter-bottomsheet {
    z-index: 99999;
}
    .charge-filter {
        background: #fff;

        .filter-list {
            left: 0;
            bottom: 0;
            width: 100%;
            background: #fff;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            overflow: scroll;
            z-index: 100;
            padding-bottom: 90px;
            height: 50%;
            max-height: 90%;
            overflow-y: auto;

            ul {
                padding: 8px 20px ;

                li {
                    position: relative;
                    padding: 15px 75px 15px 15px;

                    input[type=checkbox] {
                        display: none;
                    }

                    input[type=checkbox]+label {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background: url('../../assets/images/common/check-none.svg') no-repeat;
                        background-size: cover;
                    }

                    .filter-checkbox {
                        // padding: 30px;
                        z-index: inherit
                    }

                    .filter-checkbox input[type=checkbox]:checked+label {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        background: url('../../assets/images/common/check.svg') no-repeat;
                    }

                    // input[type=checkbox] {
                    //     position: absolute;
                    //     top: 15px;
                    //     right: 15px;
                    //     display: inline-block;
                    //     width: 20px;
                    //     height: 20px;
                    //     background: url(../../assets/images/check-none.svg) no-repeat;
                    //     background-size: cover;
                    //     border-radius: 60px;

                    //     &:checked {
                    //         // display: none;
                    //         // margin-right: 15px;
                    //         position: absolute;
                    //         width: 20px;
                    //         height: 20px;
                    //         background: url('../../assets/images/check.svg') no-repeat;
                    //     }
                    // }
                    // border-top: 1px solid #f1f1f1;

                    // .list-title{
                    //     width: ;
                    //     height: ;
                    //     display: block;
                    // }
                    // i {
                    //     position: absolute;
                    //     top: 13px;
                    //     right: 15px;
                    //     display: inline-block;
                    //     width: 36px;
                    //     height: 36px;
                    //     background: url(../../assets/images/icon-check.svg) no-repeat;
                    //     background-size: cover;
                    // }

                    h3 {
                        @include TMOBI300;
                        font-size: 17px;
                        line-height: 24px;
                        color: #000;
                    }

                    .filter-img {
                        position: absolute;
                        left: 0;
                        bottom: 10px;
                        display: inline-block;
                        width: 36px;
                        height: 36px;
                        // background: url(../../assets/images/icon-check.svg) no-repeat;
                        background-size: cover;
                    }
                }
            }
        }


        // .filter-list {
        //     top: 0;
        //     left: 0;
        //     padding: 20px 20px 100px 20px;
        //     width: 100%;
        //     background: #fff;
        //     z-index: 100; 
        //     height: 100%;
        //     position: relative;
        //     text-align: left; 
        //     li{

        //     }

        //     .list-title {
        //       @include TMOBI500;
        //       font-size: 18px;
        //       color: #111;
        //       line-height: 56px; 
        //     }
        //     h3{
        //         font-size: 12px;
        //         line-height: 34px;
        //         @include TMOBI300;
        //         color: #111;
        //     }
        //     i{
        //         position:absolute;
        //         top:3px;
        //         right:15px;
        //         display: inline-block;
        //         width:36px;
        //         height: 36px;
        //         background: url(../../assets/images/icon-check.svg) no-repeat;
        //         background-size: cover;
        //     }
        // }


    }

// }

.filter-button {
    height: 90px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 20px 20px;
    background: #fff;
    z-index: 1000;

    &:before {
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    .no-button-group {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 33%;
        padding: 0 5px 20px 20px;
        background: #fff;
        z-index: 1000;

        button {
            @include TMOBI300;
            position: relative;
            display: block;
            width: 100%;
            height: 51.5px;
            border-radius: 8px;
            border: solid 1px #EEF0F3;
            background-color: #EEF0F3;
            font-size: 17px;
            text-align: center;
            color: #111;
            // i{
            //     display: inline-block;
            //     width:24px;
            //     height:24px;
            //     position:relative;
            //     background: url(../../assets/images/ic-qr-scanning.svg);
            //     background-size: cover;
            //     margin-right:4px;
            //     vertical-align: middle;
            // }
        }
    }

    .yes-button-group {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 68%;
        padding: 0 20px 20px 5px;
        //background: #fff;
        z-index: 1000;

        button {
            @include TMOBI500;
            position: relative;
            display: block;
            width: 100%;
            height: 51.5px;
            border-radius: 8px;
            //border: solid 1px #0064ff;
            background-color: #EEF0F3;
            font-size: 17px;
            text-align: center;
            color: #fff;
            outline: none;
            // i{
            //     display: inline-block;
            //     width:24px;
            //     height:24px;
            //     position:relative;
            //     background: url(../../assets/images/ic-qr-scanning.svg);
            //     background-size: cover;
            //     margin-right:4px;
            //     vertical-align: middle;
            // }
        }
    }
}



.map-search {

    .search {
        position: relative;
        padding: 10px 15px 0;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -7px;
            width: 100%;
            height: 0px;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 70%);
        }

        i {
            position: absolute;
            top: 19px;
            left: 25px;
            // display: inline-block;
            width: 28px;
            height: 28px;
            background: url(../../assets/images/common/ico_arrow_left_2.0.svg) no-repeat;
            background-size: cover;
        }

        input[type="text"] {
            @include TMOBI500;
            width: 100%;
            height: 48px;
            border: 1px solid #E0E2E8;
            border-radius: 26px;
            padding: 0 40px;
            font-size: 17px;
            line-height: 1.6;
            color: #111;
            outline: none;

            &::placeholder {
                @include TMOBI300;
                // line-height: 1.6;
                color: #BEC5CC;
                font-size: 18px;
            }
        }

        button {
            position: absolute;
            right: 20px;
            top: 2px;
            font-size: 0;
            width: 36px;
            height: 36px;
            background: url(../../assets/images/common/ic-searchbar-search.svg) no-repeat;
            background-size: cover;
        }
    }
}

.search-list {
    background-color: white;
    margin-top: 15px;
    z-index: 5;
    position: relative;

    // .inner-box {
    // 	position: relative;
    // 	overflow-y: auto; 
    // 	background-color: white; 
    .list-box {
        // position:absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow: hidden;
        z-index: 100;
        height: 50%;
        max-height: 90%;
        overflow-y: auto;

        .no-search{
            @include TMOBI500;
            font-size: 16px;
            color: #51565C;
            text-align: center;
            padding-top: 55%;
        }

        .after-list {
            ul {
                padding: 0px 20px;
                border-top: 1px solid #EEF0F3;

                li {
                    position: relative;
                    line-height: 1.65;
                    .title {
                        @include TMOBI500;
                        font-size: 16px;
                        color: #171819;
                    }
                    .addr {
                        @include TMOBI300;
                        font-size: 14px;
                        color: #8C9299;
                    }
                }
            }

            span {
                display: block;
                margin: 235px 20px 353px;
                @include TMOBI300;
                font-size: 16px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #51565C;
            }
        }

        .search-delete {
            padding: 5px 25px;
            height: 100%;
            .recently{
                display: flex;
                justify-content: space-between;
                @include TMOBI300;
                padding: 10px 0 15px 0;
                .title{
                    position: relative;
                    top: 5px;
                    font-size: 14px;
                    color: #A1A3A6;
                }
            }

            .status {
                @include TMOBI300;
                background: #fff;
                color: #666;
                padding: 5px 13px;
                font-size: 13px;
                border-radius: 16px;
                border: 1px solid #f1f1f1;
            }

            .before-list {
                .delete-list {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 0px;
                    border-bottom: 1.5px solid #f1f1f1;
                    line-height: 2;
                    .title{
                        @include TMOBI300;
                        color: #111;
                        font-size: 16px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.nocharge {
    z-index: 9999999;
    text-align: center;
    // top: 30px;
    // position: relative;
    margin-top: 30%;

    .normal {
        @include TMOBI500;
        font-size: 18px;
        color: #51565C;
        line-height: 2.5;
        letter-spacing: 0.5px;
    }

    .grey {
        @include TMOBI300;
        font-size: 16px;
        color: #8C9299;
        letter-spacing: 0.5px;
    }
}

.main-bottomSheet {
    background-color: white;
    margin-bottom: 5%;
    // padding: 0 20px 20px;

    // margin-bottom: 150px;
    // .inner-box {
    // 	position: relative;
    // 	overflow-y: auto;
    // 	background-color: white;
    .filter {
        position: fixed;
        // top: 110px;
        // padding: 20px 0 0;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        white-space: nowrap;
        height: 50px;
        // background-color: #fff;
        z-index: 99999999999;
        background: #fff;

        &::-webkit-scrollbar {
            display: none;
        }

        .inner-wrap {
            width: 600px;
            height: 40px;
            padding: 10px 0 0 20px;
            display: flex;
            align-items: center;
            background-color: #fff;

            // margin-right: 30px;
            button {
                @include TMOBI300;
                display: inline-block;
                padding: 0 10px;
                border-radius: 15px;
                height: 30px;
                text-align: center;
                border : 1px solid #E0E2E8;
                // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                // background: url(../../assets/images/ic-filter-arrow-down-enabled.svg) no-repeat;
                // background-size: 12px;
                // background-position: right;
                background-color: #F8F9FA;
                font-size: 14px;
                color: #111;
            }

            .buttonN {
                @include TMOBI300;
                display: inline-block;
                padding: 5px 22px 5px 10px;
                border-radius: 15px;
                height: 30px;
                text-align: center;
                border : 1px solid #E0E2E8;
                // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                background: url(../../assets/images/common/ic-filter-arrow-down-enabled.svg) no-repeat;
                background-size: 12px;
                background-position: 90% 45%;
                background-color: #0064ff;
                font-size: 14px;
                color: #fff;
            }

            .buttonY {
                @include TMOBI500;
                display: inline-block;
                // padding: 5px 22px 5px 10px;
                border-radius: 15px;
                height: 30px;
                text-align: center;
                border : 1px solid #6E747A;
                // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                // background: url(../../assets/images/ic-filter-arrow-down-enabled.svg) no-repeat;
                // background-size: 12px;
                // background-position: 95% 45%;
                background-color: #F8F9FA;
                font-size: 14px;
                color: #111;
                &:after{
                    content: "";
                    display: inline-block;
                    position: relative;
                    width: 12px;
                    height: 12px;
                    right: -3px;
                    background: url(../../assets/images/common/ic-filter-arrow-down-enabled.svg) no-repeat;
                    background-size: cover;
                }
            }

            select {
                margin-left: 5px;
                @include TMOBI300;
                display: inline-block;
                height: 30px;
                padding: 0px 24px 0 10px;
                border-radius: 15px;
                // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                background: url(../../assets/images/common/ic-filter-arrow-down-selected.svg) no-repeat;
                background-color: #F8F9FA;
                // background-size:12px;
                font-size: 13px;
                color: #fff;
            }
        }
        &:after{
            content: "";
            position:absolute;
            // top:-20px;
            left:0;
            display: block;
            width:100%;
            height: 30px;
            background: linear-gradient(180deg, #FFF 37.5%, rgba(255, 255, 255, 0.00) 100%);
            // background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
        }
    }
    .list-box {
        // position:absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        // border-top-left-radius: 15px;
        // border-top-right-radius: 15px;
        overflow: hidden;
        z-index: 100;
        // padding-bottom:65px;
        height: 50%;
        max-height: 90%;
        overflow-y: auto;
        padding: 0 0 60px 0;
        // margin-top: 80px;

        .list-item {
            padding: 16px 20px;
            border-bottom: 1px solid #f1f1f1;
        }

        ul {
            li {
                display: flex;
                position: relative;

                img {
                    width: 18px;
                }

                h4 {
                    @include TMOBI500;
                    font-size: 18px;
                    line-height: 1.6;
                    color: #000;
                }

                .addr {
                    @include TMOBI300;
                    font-size: 13px;
                    line-height: 1.54;
                    color: #6E747A;
                }

                .station-info-group{
                    display: flex;
                    padding: 5px 0;
                    .info{
                        display: flex;
                        @include TMOBI300;
                        font-size: 12px;
                        line-height: 1.54;
                        color: #6E747A;
                        // padding: 0 3px
                        background-color: #F8F9FA;
                        margin-right: 4px;
                        padding: 2px 4px;
                        border-radius: 3px;
                        .cpo-img{
                            position: relative;
                        }
                    }
                }

                .result {
                    margin-top: 4px;
                    font-size: 13px;
                    line-height: 1.54;
                    color: #00ACE5;

                    em {
                        @include TMOBI300;
                        display: inline-block;
                        position: relative;
                        padding-left: 14px;

                        &:before {
                            content: "";
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            top: -11px;
                            left: 0;
                            @include TMOBI500;
                        }

                        &.type1 { //초급속 이미지
                            &:before {
                                // background: url(../../assets/images/ic-evcharger-rapid-02-enabled.svg) no-repeat;
                                background-size: cover;
                                color: #00ACE5;
                            }
                        }

                        &.type2 { //급속 이미지
                            &:before {
                                // background: url(../../assets/images/ic-evcharger-rapid-01-enabled.svg) no-repeat;
                                background-size: cover;
                                color: #00ACE5;
                            }
                        }

                        &.type3 { //급속 문구
                            &:before {
                                background-size: cover;
                                content: "급속";
                                width: 25px;
                                top: -14px;
                                color: #00ACE5;
                            }
                        }

                        &.type4 { //급속 가능 대수
                            &:before {
                                background-size: cover;
                                width: 100px;
                                top: -14px;
                                color: #00ACE5;
                            }
                        }

                        &.type5 { //완속 문구
                            &:before {
                                background-size: cover;
                                content: "완속";
                                width: 25px;
                                top: -14px;
                                color: #00ACE5;
                            }
                        }

                        &.type6 { //완속 가능 대수
                            &:before {
                                background-size: cover;
                                width: 100px;
                                top: -14px;
                                color: #00ACE5;
                            }
                        }

                        &.type7 { //초급속 문구
                            &:before {
                                background-size: cover;
                                content: "초급속";
                                width: 35px;
                                top: -14px;
                                color: #00ACE5;
                            }
                        }

                        &.type8 { //초급속 가능 대수
                            &:before {
                                background-size: cover;
                                width: 100px;
                                top: -14px;
                                color: #00ACE5;
                            }
                        }
                    }
                }

                .status {
                    @include TMOBI300;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    // transform: translateY(-50%);
                    width: 66px;
                    height: 31.5px;
                    line-height: 31.5px;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 8px;
                    border: 1px solid transparent;

                    &.type1 {
                        border-color: #0064ff;
                        background: #0064ff;
                        color: #fff;
                    }

                    &.type2 {
                        border-color: #0064ff;
                        background: #0064ff;
                        color: #fff;
                        margin: 5px;
                    }

                    &.type3 {
                        border-color: #f0f0f0;
                        background: #f0f0f0;
                        color: #d8d8d8;
                    }

                    &.type4 {
                        border-color: #3166f1;
                        background: #3166f1;
                        color: #fff;
                    }
                }
            }
        }
    }

}

.main-bottomSheet-detail{
    // background-color: white;
    // margin-bottom: 5%;
    padding: 15px 0;
    .list-box {
        // position:absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        // border-top-left-radius: 15px;
        // border-top-right-radius: 15px;
        // overflow: hidden;
        z-index: 100;
        // overflow-y: auto;
        // padding: 0 0 60px 0;

        .list-item {
            padding: 16px 20px;
        }

        img {
            width: 18px;
        }

        h4 {
            @include TMOBI500;
            font-size: 18px;
            line-height: 1.6;
            color: #000;
        }

        .addr {
            @include TMOBI300;
            font-size: 13px;
            line-height: 1.54;
            color: #6E747A;
        }

        .station-info-group{
            display: flex;
            padding: 5px 0;
            .info{
                display: flex;
                @include TMOBI300;
                font-size: 12px;
                line-height: 1.54;
                color: #6E747A;
                // padding: 0 3px
                background-color: #F8F9FA;
                margin-right: 4px;
                padding: 2px 4px;
                border-radius: 3px;
                .cpo-img{
                    position: relative;
                }
            }
        }

        .poidetail{
            @include TMOBI300;
            font-size: 14px;
            letter-spacing: normal;
            line-height: 1.54;
            color: #171819;
            background-color: #EEF0F3;
            border-radius: 8px;
            padding: 5px 15px;
            display: inline;
            float: right;
            position: relative;
            bottom: 36px;
        }

        .result {
            margin-top: 4px;
            font-size: 13px;
            line-height: 1.54;
            color: #00ACE5;

            em {
                @include TMOBI300;
                display: inline-block;
                position: relative;
                padding-left: 14px;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: -11px;
                    left: 0;
                    @include TMOBI500;
                }

                &.type1 { //초급속 이미지
                    &:before {
                        // background: url(../../assets/images/ic-evcharger-rapid-02-enabled.svg) no-repeat;
                        background-size: cover;
                        color: #00ACE5;
                    }
                }

                &.type2 { //급속 이미지
                    &:before {
                        // background: url(../../assets/images/ic-evcharger-rapid-01-enabled.svg) no-repeat;
                        background-size: cover;
                        color: #00ACE5;
                    }
                }

                &.type3 { //급속 문구
                    &:before {
                        background-size: cover;
                        content: "급속";
                        width: 25px;
                        top: -14px;
                        color: #00ACE5;
                    }
                }

                &.type4 { //급속 가능 대수
                    &:before {
                        background-size: cover;
                        width: 100px;
                        top: -14px;
                        color: #00ACE5;
                    }
                }

                &.type5 { //완속 문구
                    &:before {
                        background-size: cover;
                        content: "완속";
                        width: 25px;
                        top: -14px;
                        color: #00ACE5;
                    }
                }

                &.type6 { //완속 가능 대수
                    &:before {
                        background-size: cover;
                        width: 100px;
                        top: -14px;
                        color: #00ACE5;
                    }
                }

                &.type7 { //초급속 문구
                    &:before {
                        background-size: cover;
                        content: "초급속";
                        width: 35px;
                        top: -14px;
                        color: #00ACE5;
                    }
                }

                &.type8 { //초급속 가능 대수
                    &:before {
                        background-size: cover;
                        width: 100px;
                        top: -14px;
                        color: #00ACE5;
                    }
                }

                .status {
                    @include TMOBI300;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    // transform: translateY(-50%);
                    width: 65px;
                    height: 28px;
                    line-height: 26px;
                    text-align: center;
                    display: inline-block;
                    font-size: 13px;
                    color: #fff;
                    border-radius: 8px;
                    border: 1px solid transparent;

                    &.type1 {
                        border-color: #0064ff;
                        background: #0064ff;
                        color: #fff;
                    }

                    &.type2 {
                        border-color: #0064ff;
                        background: #0064ff;
                        color: #fff;
                    }

                    &.type3 {
                        border-color: #f0f0f0;
                        background: #f0f0f0;
                        color: #d8d8d8;
                    }

                    &.type4 {
                        border-color: #3166f1;
                        background: #3166f1;
                        color: #fff;
                    }
                }
            }
        }
    }
}
// }
//충전소 충전기 상세정보
.charging-station {
    padding-bottom: 5%;

    button {
        position: absolute;

        &.back-btn {
            width: 46px;
            height: 46px;
            // background-color: rgba(0, 0, 0, 0.5);
            // background-color: white;
            border-radius: 100%;
            margin: 10px;
        }

        &.zoom-btn {
            right: 0;
            width: 46px;
            height: 46px;
            top: 135px;
            // background-color: rgba(0, 0, 0, 0.5);
            // background-color: white;
            border-radius: 100%;
            margin: 10px;
            z-index: 999;
            position: fixed;
        }
    }

    .title-group {
        padding: 20px;
        display: flex;
        flex-direction: column;

        .station-name {
            position: relative;
            @include TMOBI500;
            font-size: 20px;
            line-height: 1.44;
            color: #171819;
            letter-spacing: 0.001em;
            padding: 5px 0;

            // &:before{
            //     content: "";
            //     position:absolute;
            //     top:6px;
            //     left:0;
            //     display: inline-block;
            //     width: 16px;
            //     height:16px;
            //     background:url(../../assets/images/ic-poi-evstation.svg) no-repeat;
            //     background-size: cover;
            // }
        }

        span {
            @include TMOBI300;
            font-size: 14px;
            line-height: 1.54;
            color: #8C9299;
            letter-spacing: normal;
        }
    }

    .list {
        // border-top: 8px solid #f1f1f1;
        padding: 12px 20px;
    }

    .near-station {
        // border-top: 8px solid #f1f1f1;
        padding: 40px 0 0;

        .title {
            @include TMOBI500;
            font-size: 16px;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #171819;
        }

        .sub {
            @include TMOBI300;
            font-size: 14px;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #8C9299;
            padding-top: 3px;
            padding-bottom: 15px;
        }

        .list-box {
            .list-item {
                // border-bottom: 1px solid #EEF0F3;
                padding: 20px;
            }
            img {
                display: inline-block;
                position: absolute;
                left: 0;
                // top:50%;
                // transform: translateY(-50%);
                width: 25px;
                height: 25px;
            }

            h4 {
                @include TMOBI300;
                font-size: 15px;
                line-height: 1.6;
                color: #000;
            }

            .addr {
                @include TMOBI300;
                font-size: 13px;
                line-height: 1.54;
                color: #0064FF;
            }

            .result {
                margin-top: 4px;
                font-size: 13px;
                line-height: 1.54;
                color: #8C9299;

                span {
                    @include TMOBI300;
                    display: inline-block;
                    position: relative;
                    padding-left: 14px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        top: -11px;
                        left: 0;
                    }

                    &.type1 {
                        &:before {
                            background: url(../../assets/images/common/ic-evcharger-rapid-02-enabled.svg) no-repeat;
                            background-size: cover;
                        }
                    }

                    &.type2 {
                        &:before {
                            background: url(../../assets/images/common/ico_between_line.png) no-repeat;
                            background-size: cover;
                        }
                    }

                    &.type3 {
                        &:before {
                            background-size: cover;
                            content: "급속";
                            width: 25px;
                            top: -14px;
                            color: #8C9299;
                        }
                    }

                    &.type4 {
                        &:before {
                            background-size: cover;
                            width: 100px;
                            top: -14px;
                            color: #8C9299;
                        }
                    }

                    &.type5 {
                        &:before {
                            background-size: cover;
                            content: "완속";
                            width: 25px;
                            top: -14px;
                            color: #8C9299;
                        }
                    }

                    &.type6 {
                        &:before {
                            background-size: cover;
                            width: 100px;
                            top: -14px;
                            color: #8C9299;
                        }
                    }

                    &.type7 {
                        &:before {
                            background-size: cover;
                            content: "초급속";
                            width: 40px;
                            top: -14px;
                            color: #8C9299;
                        }
                    }

                    &.type8 {
                        &:before {
                            background-size: cover;
                            width: 100px;
                            top: -14px;
                            color: #8C9299;
                        }
                    }
                }
            }

            .status {
                @include TMOBI300;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 65px;
                height: 28px;
                line-height: 26px;
                text-align: center;
                display: inline-block;
                font-size: 12px;
                color: #fff;
                border-radius: 14px;
                border: 1px solid transparent;

                &.type1 {
                    border-color: #0064ff;
                    background: #0064ff;
                    color: #fff;
                }

                &.type2 {
                    border-color: #0064ff;
                    background: #fff;
                    color: #0064ff;
                }

                &.type3 {
                    border-color: #f0f0f0;
                    background: #f0f0f0;
                    color: #d8d8d8;
                }

                &.type4 {
                    border-color: #3166f1;
                    background: #3166f1;
                    color: #fff;
                }
            }
        }
    }

    .ment {
        @include TMOBI300;
        position: relative;
        padding: 15px 12px;
        border-radius: 8px;
        background-color: #F8F9FA;
        font-size: 14px;
        color: #6E747A;

        &:after {
            content: "";
        }

        span {
            @include TMOBI300;
            font-size: 13px;
            color: #666666;
            float: right;

            &:before {
                content: "";
                position: absolute;
                // left: 0;
                right: 49px;
                width: 16px;
                height: 16px;
                display: inline-block;
                background: url(../../assets/images/common/ic-info.svg) no-repeat;
                background-size: cover;
            }
        }

    }

    ul {
        margin-top: 13px;

        li {
            position: relative;
            padding: 20px 16px;
            border-radius: 10px;
            border: solid 1px #EEF0F3;
            background-color: #fff;
            display: flex;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
            align-items: center;

            em {
                position: relative;
                @include TMOBI300;
                display: inline-block;
                width: 42px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                font-size: 20px;
                border-radius: 4px;
                overflow: hidden;
            }

            .txt {
                margin-left: 12px;

                .dc {
                    font-size: 0;

                    span {
                        position: relative;
                        display: inline-block;
                        padding-left: 18px;
                        font-size: 14px;
                        @include TMOBI300;

                        // color: #222;
                        &+span {
                            margin-left: 6px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                        }

                        &.type1 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-1.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type2 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-2.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type3 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-3.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type4 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-4.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type5 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-5.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type1_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-1-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type2_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-2-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type3_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-3-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type4_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-4-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                }

                .info {
                    margin-top: 4px;
                    font-size: 0;

                    span {
                        position: relative;
                        display: inline-block;
                        @include TMOBI300;
                        font-size: 12px;
                        color: #909090;

                        &+span {
                            margin-left: 13px;
                        }

                        &:before {
                            content: "";
                            display: inline-block;
                            width: 1px;
                            height: 12px;
                            background: #e0e0e0;
                            position: absolute;
                            left: -6px;
                            top: 3px;
                        }

                        &:first-child {
                            &:before {
                                display: none;
                            }
                        }

                        &.kw {
                            padding-left: 16px;

                            &:after {
                                content: "";
                                position: absolute;
                                left: 0;
                                width: 16px;
                                height: 16px;
                                margin: 0 -10px -10px -0;
                            }

                            &.type1 {
                                &:after {
                                    background: url(../../assets/images/common/ic-evcharger-rapid-01-disabled.svg) no-repeat;
                                    background-size: cover;
                                }
                            }

                            &.type2 {
                                &:after {
                                    background: url(../../assets/images/common/ic-evcharger-rapid-02-disabled.svg) no-repeat;
                                    background-size: cover;
                                }
                            }

                            &.type3 {
                                &:after {
                                    background: url(../../assets/images/common/ic-evcharger-rapid-gray-01-disabled.svg) no-repeat;
                                    background-size: cover;
                                }
                            }
                        }
                    }
                }
            }

            .time {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 13.5px;
                @include TMOBI300;

                &.color1 {
                    color: #0064ff;
                }

                &.color2 {
                    color: #909090;
                }

                &.color3 {
                    color: #e55545;
                }

                &.color4 {
                    color: #d8d8d8;
                }
            }

            &+li {
                margin-top: 10px;
            }

            &.finish {
                em {
                    border: 1px solid #c6cad2;
                    line-height: 33px;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 8px;
                        background-image: linear-gradient(to right, #ff4db7, #0064ff 34%, #00daf0 71%, #32e6af);
                    }
                }
            }
        }
    }

    .dim_btn {
        color: #d8d8d8;

        li {
            border: solid 1px #c6cad2;

            .dim_btn_em {
                border: 1px solid #000;
                // color: #d8d8d8;
                line-height: 33px;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 8px;
                    background-image: linear-gradient(to right, #ff4db7, #0064ff 34%, #00daf0 71%, #32e6af);
                    // opacity: 0.4;
                }
            }
        }

        // }
    }

    .nodata {
        height: calc(100vh - 290px);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        figure {
            position: absolute;
            margin-bottom: 30%;

            img {
                width: 142px;
                height: 64px;
            }
        }

        p {
            margin-top: 16px;
            @include TMOBI300;
            font-size: 15px;
            line-height: 1.47;
            color: #111;

            em {
                color: #e55545;
            }
        }
    }

    .button-list-group {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 20px 20px;
        background: #fff;
        z-index: 1000;
        border-radius: 10px;

        .qr-title-group {
            padding: 20px 0 30px 0;
            display: flex;
            flex-wrap: wrap;

            h3 {
                position: relative;
                @include TMOBI500;
                font-size: 18px;
                line-height: 1.44;
                color: #111;
                padding-left: 15px;
                // &:before{
                //     content: "";
                //     position:absolute;
                //     top:6px;
                //     left:0;
                //     display: inline-block;
                //     width: 16px;
                //     height:16px;
                //     background:url(../../assets/images/ic-poi-evstation.svg) no-repeat;
                //     background-size: cover;
                // }
            }

            p {
                @include TMOBI300;
                font-size: 13px;
                color: #4d4d4d;
                padding: 10px 0 0 15px;
            }
        }

        // &:before{
        //     content: "";
        //     position:absolute;
        //     top:-20px;
        //     left:0;
        //     display: block;
        //     width:100%;
        //     height: 20px;;
        //     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
        // }
        button {
            @include TMOBI300;
            position: relative;
            display: inline-block;
            width: 100%;
            height: 51.5px;
            border-radius: 8px;
            border: solid 1px #EEF0F3;
            background-color: #EEF0F3;
            font-size: 17px;
            text-align: center;
            color: #111;

            i {
                display: inline-block;
                width: 24px;
                height: 24px;
                position: relative;
                background: url(../../assets/images/common/ic-qr-scanning-enabled.svg);
                background-size: cover;
                margin-right: 4px;
                vertical-align: middle;
            }

        }
    }

    .button-group {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 20px 20px;
        background: #fff;
        z-index: 1000;
        display: flex;

        &:before {
            content: "";
            position: absolute;
            top: -20px;
            left: 0;
            display: block;
            width: 100%;
            height: 20px;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
        }

        button {
            @include TMOBI300;
            position: relative;
            display: inline-block;
            width: 100%;
            height: 51.5px;
            border-radius: 8px;
            background-color: #EEF0F3;
            font-size: 17px;
            text-align: center;
            color: #111;

            i {
                display: inline-block;
                width: 24px;
                height: 24px;
                position: relative;
                background: url(../../assets/images/common/ic-qr-scanning-enabled.svg);
                background-size: cover;
                margin-right: 4px;
                vertical-align: middle;
            }

        }
    }
}


.button-group-detail {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 20px 20px;
    // background: #fff;
    z-index: 1000;

    button {
        @include TMOBI300;
        position: relative;
        display: inline-block;
        width: 100%;
        height: 51.5px;
        border-radius: 8px;
        background-color: #EEF0F3;
        font-size: 17px;
        text-align: center;
        color: #111;

        i {
            display: inline-block;
            width: 24px;
            height: 24px;
            position: relative;
            background: url(../../assets/images/common/ic-qr-scanning-enabled.svg);
            background-size: cover;
            margin-right: 4px;
            vertical-align: middle;
        }
    }
}

.bottomsheet-header {
    // &:after{
    //     content: "";
    //     position:absolute;
    //     // top:-20px;
    //     left:0;
    //     display: block;
    //     width:100%;
    //     height: 30px;
    //     background: linear-gradient(180deg, #FFF 37.5%, rgba(255, 255, 255, 0.00) 100%);
    //     // background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
    // }
    // height: 50px;
    .header-bar {
        width: 32px;
        height: 4px;
        flex-shrink: 0;
        border-radius: 4px;
        background: var(--gray-color-gray-300, #E0E2E8);
        margin: 0 auto;
        position: relative;
        top: -10px;
    }
    .header-text {
        margin: 20px 0 10px 20px;
        display: flex;
        align-items: center;
        height: 20px;

        .count {
            @include TMOBI500;
        }

        .delivery-button {
            width: 57px;
            text-align: center;
            height: 28px;
            border-radius: 4px;
            // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.22);
            background-color: #EEF0F3;
            margin-left: auto;
            margin-right: 20px;

            // &:before {
            //     content: "";
            //     display: inline-block;
            //     width: 24px;
            //     height: 24px;
            //     background: url(../../assets/images/common/ic-ev-delivery-car.svg) no-repeat;
            //     position: relative;
            //     margin-right: 8px;
            //     vertical-align: middle;
            // }

            span {
                display: inline-block;
                vertical-align: middle;
                @include TMOBI500;
                font-size: 11px;
                font-stretch: normal;
                font-style: normal;
                color: #000;
                letter-spacing: normal;
                text-align: center;

                // background: linear-gradient(to right, #0064ff, #ff4db7);
                // -webkit-background-clip: text;
                // background-clip: text;
                // -webkit-text-fill-color: transparent;
            }

        }
    }
    // .filter {
    //     position: relative;
    //     // top: 110px;
    //     padding: 20px 0 0;
    //     width: 100%;
    //     overflow-x: auto;
    //     -ms-overflow-style: none;
    //     scrollbar-width: none;
    //     white-space: nowrap;

    //     &::-webkit-scrollbar {
    //         display: none;
    //     }

    //     .inner-wrap {
    //         width: 500px;
    //         padding: 0 0 0 20px;
    //         display: flex;
    //         align-items: center;

    //         // margin-right: 30px;
    //         button {
    //             @include TMOBI300;
    //             display: inline-block;
    //             padding: 0 10px;
    //             border-radius: 15px;
    //             height: 30px;
    //             text-align: center;
    //             border : 1px solid #E0E2E8;
    //             // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    //             // background: url(../../assets/images/ic-filter-arrow-down-enabled.svg) no-repeat;
    //             // background-size: 12px;
    //             // background-position: right;
    //             background-color: #F8F9FA;
    //             font-size: 13px;
    //             color: #111;
    //         }

    //         .buttonN {
    //             @include TMOBI300;
    //             display: inline-block;
    //             padding: 5px 22px 5px 10px;
    //             border-radius: 15px;
    //             height: 30px;
    //             text-align: center;
    //             border : 1px solid #E0E2E8;
    //             // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    //             background: url(../../assets/images/ic-filter-arrow-down-enabled.svg) no-repeat;
    //             background-size: 12px;
    //             background-position: 90% 45%;
    //             background-color: #0064ff;
    //             font-size: 14px;
    //             color: #fff;
    //         }

    //         .buttonY {
    //             @include TMOBI500;
    //             display: inline-block;
    //             padding: 5px 22px 5px 10px;
    //             border-radius: 15px;
    //             height: 30px;
    //             text-align: center;
    //             border : 1px solid #6E747A;
    //             // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    //             background: url(../../assets/images/ic-filter-arrow-down-enabled.svg) no-repeat;
    //             background-size: 12px;
    //             background-position: 90% 45%;
    //             background-color: #F8F9FA;
    //             font-size: 14px;
    //             color: #111;
    //         }

    //         select {
    //             margin-left: 5px;
    //             @include TMOBI300;
    //             display: inline-block;
    //             height: 30px;
    //             padding: 0px 24px 0 10px;
    //             border-radius: 15px;
    //             // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    //             background: url(../../assets/images/ic-filter-arrow-down-selected.svg) no-repeat;
    //             background-color: #F8F9FA;
    //             // background-size:12px;
    //             font-size: 13px;
    //             color: #fff;
    //         }
    //     }
    // }
    // .header-gradient {
    //     content: "";
    //     position:absolute;
    //     top:0;
    //     left:0;
    //     display: block;
    //     width:100%;
    //     height: 20px;
    //     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    // }
}

.util-group {
    padding: 0 20px;
    top: 25%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pnc-button {
        padding: 0 20px;
        text-align: center;
        height: 46px;
        border-radius: 50px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.22);
        background-color: #000000;
        display: flex;
        align-items: center;

        span {
            display: inline-block;
            // vertical-align: middle;
            @include TMOBI500;
            font-size: 14px;
            color: #fff;
            line-height: 1.43;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url(../../assets/images/common/ic-pnc.svg) no-repeat;
            position: relative;
        }

    }

    button {
        position: relative;
        display: inline-block;
        font-size: 0;

        &.refresh-button {
            // width:fit-content;
            padding: inherit;
            text-align: center;
            height: 46px;
            border-radius: 50px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.22);
            background-color: #fff;

            span {
                display: inline-block;
                // vertical-align: middle;
                @include TMOBI500;
                font-size: 14px;
                color: #000;
                line-height: 1.43;
                // font-stretch: normal;
                // font-style: normal;
                // width: fit-content;
                // letter-spacing: normal;
                // text-align: center;
                // background: #000;
                // -webkit-background-clip: text;
                // background-clip: text;
                // -webkit-text-fill-color: transparent;
            }

        }
        // &.location-button{
        // 	width:46px;
        // 	height: 46px;
        // 	background: #fff;
        // 	border-radius:100%;

        // }
        // &.delivery-button {
        //     width: 187px;
        //     text-align: center;
        //     height: 46px;
        //     border-radius: 21px;
        //     box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.22);
        //     background-color: #fff;

        //     // &:before {
        //     //     content: "";
        //     //     display: inline-block;
        //     //     width: 24px;
        //     //     height: 24px;
        //     //     background: url(../../assets/images/common/ic-ev-delivery-car.svg) no-repeat;
        //     //     position: relative;
        //     //     margin-right: 8px;
        //     //     vertical-align: middle;
        //     // }

        //     span {
        //         display: inline-block;
        //         vertical-align: middle;
        //         @include TMOBI300;
        //         font-size: 15px;
        //         font-stretch: normal;
        //         font-style: normal;
        //         letter-spacing: normal;
        //         text-align: center;
        //         background: linear-gradient(to right, #0064ff, #ff4db7);
        //         -webkit-background-clip: text;
        //         background-clip: text;
        //         -webkit-text-fill-color: transparent;
        //     }

        // }

        // &.pnc-button {
        //     // width:fit-content;
        //     padding: inherit;
        //     text-align: center;
        //     height: 46px;
        //     border-radius: 50px;
        //     box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.22);
        //     background-color: #000000;
        //     display: flex;
        //     align-items: center;
        //     // -webkit-appearance: "none";
        //     // position: "absolute";
        //     // left: "7px";
        //     // top: "-65px";
        //     // z-index: "5";

        //     span {
        //         display: inline-block;
        //         // vertical-align: middle;
        //         @include TMOBI500;
        //         font-size: 14px;
        //         color: #fff;
        //         line-height: 1.43;
        //     }

        //     &:before {
        //         content: "";
        //         display: inline-block;
        //         width: 20px;
        //         height: 20px;
        //         background: url(../../assets/images/common/ic-pnc.svg) no-repeat;
        //         position: relative;
        //     }

        // }

        // &.credit-button{
        // 	width:46px;
        // 	height: 46px;
        // 	background: #fff;
        // 	border-radius:100%;
        // }
    }
}

.popover {
    @include TMOBI300;
    background: linear-gradient(7deg, #0064FF 33.17%, #34C7C2 86.88%);
    border-radius: 53px;
    height: 36px;
    position: relative;
    width: 205px;
    z-index: 3;

    // button {
    //     position: relative;
    //     top: -15px;
    //     left: 3px;
    //     // display: inline-block;
    //     width: 15px;
    //     height: 15px;
    //     background: url(../../assets/images/ic-top-close.svg) no-repeat;
    //     background-size: cover;
    //     font-size: 0;
    // }
    .popover-content {
        @include TMOBI500;
        font-size: 16px;
        height: 100%;
        width: 100%;
        // padding: 20px;
        line-height: 36px;
        text-align: center;
        color: #fff;
        // padding: 20px;
        // span {
        // }
    }
    &:after {
        content: '';
        background: url(../../assets/images/common/img_tail_blue_down.svg) no-repeat;
        display: block;
        right: 20px;
        position: absolute;
        width: 12px;
        height: 10px;
        bottom: -9px;
    }
}


// .popover::before {
//     border-color: #0064ff transparent;
//     border-style: solid;
//     border-width: 9px 0px 0 9px;
//     content: '';
//     display: block;
//     left: 86px;
//     position: absolute;
//     top: 43px;
//     width: 0;
//     z-index: 0;
// }

.banner {
    padding: 8px 20px;
    display: block;

    img {
        display: block;
        width: 100%;
    }
}

.finish-charge {
    padding : 40px 0 100px;

    .cancel-header{
        background-color: #E6F1FF;
        border-radius: 8px;
        padding: 20px;
        margin: 20px 15px 10px;
        @include TMOBI300;
        color: #51565C;
        font-size: 16px;
        .cancel-content{
            @include TMOBI300;
            color: #8C9299;
            font-size: 12px;
            padding-top: 5px;
            letter-spacing: normal;
        }
    }
    .top-section {
        padding: 20px;
        .finish-title{
            @include TMOBI500;
            color: #171819;
            letter-spacing: normal;
            font-size: 24px;
            line-height: 1.9;
        }
        .charge-kwh{
            @include TMOBI300;
            color: #8C9299;
            letter-spacing: normal;
            font-size: 16px;
            line-height: 1.9;
            padding-top: 20px;
        }
        .time-info{
            @include TMOBI500;
            color: #171819;
            letter-spacing: normal;
            font-size: 14px;
        }
        .addr-info{
            @include TMOBI300;
            margin-top: 24px;
            letter-spacing: normal;
            position: relative;
            font-size: 16px;
            color: #111;
            line-height: 1.8;
            text-align: left;
            span{
                display: block;
                font-size: 13px;
                line-height: 20px;
                color: #909090;
            }
        }
    }

    .finish-payinfo{
        padding: 0 20px;
        .coupon-box{
            padding: 20px 0 10px;
            border-bottom: 1px solid #EEF0F3;
            line-height: 1.8;
            .info-list{
                display: flex;
                justify-content: space-between;
                @include TMOBI300;
                color: #8C9299;
                letter-spacing: normal;
                font-size: 16px;
                padding: 2px 0;
                .amount{
                    color:#171819;
                }
                .coupon-section{
                    color: #0064ff;
                }
            }
        }
        .credit-box{
            display: flex;
            justify-content: space-between;
            @include TMOBI300;
            color: #171819;
            letter-spacing: normal;
            font-size: 16px;
            padding: 20px 0;
            .selected, .NoFocus{
                button{
                    @include TMOBI300;
                    color: #171819;
                    font-size: 16px;
                    letter-spacing: normal;
                    &:after{
                        content: "";
                        display: inline-block;
                        position: relative;
                        top: 3px;
                        right: -3px;
                        width: 16px;
                        height: 16px;
                        background: url(../../assets/images/common/icon_common_arrow_20.png) no-repeat;
                        background-size: cover;
                    }
                }
                .card{
                    @include TMOBI300;
                    color: #171819;
                    font-size: 16px;
                    letter-spacing: normal;
                }
            }
            .pay-price{
                color: #0064ff;
                @include TMOBI500;
            }
        }
    }

    .button-box {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 20px 20px;
        background: #fff;
        z-index: 1000;

        button {
            @include TMOBI500;
            position: relative;
            display: inline-block;
            width: 100%;
            height: 50px;
            border-radius: 8px;
            background-color: #EEF0F3;
            font-size: 17px;
            text-align: center;
            color: #111;
        }
    }
}

.review {
    .review-top {
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 100;
        // height: 56px;
        position: relative;
        text-align: center;
        border-radius: 15px;

        .review-title {
            position: relative;
            // top: 2vh;
            @include TMOBI500;
            font-size: 19px;
            color: #111;
            padding: 30px 0;
            letter-spacing: normal;
            line-height: 1.45;

            // line-height: 78px;
            span {
                font-size: 13px;
                color: #a1a3a6;
                @include TMOBI300;
            }
        }

        button {
            @include TMOBI300;
            position: absolute;
            top: 20px;
            right: 10px;
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url(../../assets/images/common/ic-top-close.svg) no-repeat;
            background-size: cover;
            font-size: 0;
        }
    }

    .txt-wrap {
        padding: 10px 40px 120px;

        h3 {
            position: relative;
            @include TMOBI500;
            font-size: 18px;
            line-height: 1.44;
            color: #111;
            text-align: center;

            &:before {
                content: "";
                position: relative;
                top: 3px;
                left: 0;
                margin-right: 6px;
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url(../../assets/images/cpo/ic-poi-evstation.svg) no-repeat;
                background-size: cover;
            }
        }

        p {
            @include TMOBI500;
            margin-top: 4px;
            font-size: 15px;
            text-align: center;
            color: #111;

        }

        .button-wrap {
            // margin-top: 19px;
            text-align: center;
            font-size: 0;

            button {
                @include TMOBI300;
                position: relative;
                display: inline-block;
                border-radius: 20px;
                border: 1px solid var(--gray-color-gray-300, #E0E2E8);
                // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                background-color: #fff;
                // padding: 0 16px 0 0;
                font-size: 14px;
                color: var(--gray-color-gray-700, #51565C);
                margin: 5px;
                height: 40px;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: -1px;
                    margin-right: 6px;
                    vertical-align: middle;
                }

                &.on {
                    border-color: #0064ff;
                    color: #0064ff;
                    background-color: #f5f8fe;
                    border: solid 1px #cfdffc;
                }

                &.type-didNotWorkWell {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-easy.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "충전이 잘 안됨";
                    }
                }

                &.type-shortWaiting {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-charging-issue.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "대기 없음";
                    }
                }

                &.type-worksWell {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-charging.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "충전이 잘 됨";
                    }
                }

                &.type-easyToUse {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-parking.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "사용하기 쉬움";
                    }
                }

                &.type-freeParking {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-clean.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "주차무료";
                    }
                }

                &.type-easyToFind {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-standby.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "찾기 쉬움";
                    }
                }

                &.type-neat {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-place.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "깨끗함";
                    }
                }

                &.type-noneOfTheseApply {
                    // &:before {
                    //     background: url(../../assets/images/ic-review-none.svg) no-repeat;
                    //     background-size: cover;
                    // }
                    &:after {
                        position: relative;
                        right: 11.5px;
                        content: "해당 항목 없음";
                    }
                }

                // &.type0 {
                //     &:before {
                //         background: url(../../assets/images/ic-review-emoji-positive-enabled.svg) no-repeat;
                //         background-size: cover;
                //     }
                // }
            }
        }
    }

    .review-button {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 20px;
        width: 100%;
        background: hsla(0, 0%, 100%, .7);
        button {
            @include TMOBI500;
            position: relative;
            display: inline-block;
            width: 100%;
            height: 50px;
            border-radius: 8px;
            background-color: #EEF0F3;
            font-size: 17px;
            text-align: center;
            color: #111;
        }
    }

    .button-box {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 20px;
        width: 100%;
        background: hsla(0, 0%, 100%, .7);
    }

    .button-box button {
        @include TMOBI300;
        position: relative;
        display: inline-block;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: solid 1px #c6cad2;
        background-color: #0064ff;
        font-size: 17px;
        text-align: center;
        color: #fff;
    }
}


.easypay-guide {
    .charger-img {
        .content {
            // padding: 20px;

            .big-content {
                @include TMOBI500;
                font-size: 24px;
                text-align: center;
                letter-spacing: normal;
            }

            .small-content {
                font-size: 15px;
                @include TMOBI300;
                text-align: center;
                letter-spacing: normal;
                margin-top: 20px;
                color: #8C9299;
                line-height: 1.5;

                .color-blue {
                    color: #0064ff;
                }

                li {
                    display: flex;
                    list-style: none;

                    p {
                        padding-left: 10px;
                        @include TMOBI300;
                    }
                }

                li:before {
                    content: "·"
                }
            }
        }

        img {
            margin: 0 auto;
            padding-top: 30px;
            width: 80%;
        }
    }
}

.dots_custom {
    display: inline-block;
    vertical-align: middle;
    margin: auto 0;
    padding: 0;
}

.dots_custom li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px;
    padding: 0;
}

.dots_custom li button {
    border: none;
    background: #d1d1d1;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    padding: 0;
}

.dots_custom li.slick-active button {
    background-color: #0064ff;
}

.slick-slider {
    height: 100%;
}

.slick-list {
    height: 100%;
    height: fit-content;
    border-radius: 5px;
}

.slick-track {
    height: 100%;
}

.slider_custom {
    height: 100%;
}

.banner_slider_custom{
    // width: 80%;
    display: flex;
    height: 200px;
}

.info-popup-bottom {
    bottom: 20px;
    padding: 0 20px;
    position: fixed;
    z-index: 9999;
    width: 100%;
    margin: 0 auto;

    button {
        flex: 1;
        line-height: 52px;
        background: #fff;
        border-radius: 10px;
        font-size: 17px;
        color: #111;
        display: inline-block;
        text-align: center;
        width: 40%;
        border: 1px solid #ddd;
    }

    button+button {
        color: #fff;
        background: #0064ff;
        margin-left: 8px;
        width: 57%
    }
}

.ev-push {
    button {
        position: absolute;

        &.back-btn {
            width: 30px;
            height: 30px;
            margin: 15px;
        }
    }

    .main-info {
        letter-spacing: normal;

        .inline-text {
            position: absolute;
            z-index: 9999;
            top: 18%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #fff;
            width: max-content;
            line-height: 1.45;

            .border-text {
                border: 1px solid #fff;
                @include TMOBI300;
                border-radius: 30px;
                padding: 6px 12px;
                font-size: 14px;
            }

            .main-text {
                @include TMOBI500;
                font-size: 30px;
                background-color: #001C48;
            }

            &:after {
                // background: url(../../assets/images/ic_title_ev_mark.svg) no-repeat;
                // background-size: cover;
            }
        }

        .first {
            background-color: #f5f5f5;
            text-align: center;

            .first-comment {
                font-style: normal;
                font-size: 14.5px;
                line-height: 1.5;
                text-align: center;
                @include TMOBI500;
                color: #083D8D;
                // transform: rotate(0.16deg);
                padding: 30px 0 20px 0;
            }

            button {
                background-color: #111;
                color: #fff;
                position: relative;
                font-size: 13px;
                @include TMOBI500;
                // padding: 20px 50px;
                width: 80%;
                padding: 14px 0;
                border-radius: 8px;
                margin-bottom: 30px;
            }
        }
    }

    .second {
        background-color: #083D8D;
        @include TMOBI300;
        color: #fff;
        text-align: center;
        line-height: 1.7;
        padding: 30px 0;
        font-size: 17px;
        margin-top: 30px;
        letter-spacing: normal;
    }

    .third {
        margin-top: 30px;
        background-color: #F5F5F5;
        @include TMOBI300;
        text-align: center;
        letter-spacing: normal;
        padding-bottom: 30px;

        button {
            background-color: #111;
            color: #fff;
            position: relative;
            font-size: 12.5px;
            @include TMOBI500;
            // padding: 20px 50px;
            width: 80%;
            padding: 14px 0;
            border-radius: 8px;
            // margin-bottom: 30px;
        }
    }

    .last {
        background-color: #E6EDF9;
        @include TMOBI300;
        text-align: center;
        line-height: 1.7;
        font-size: 15px;
        margin-top: 30px;
        letter-spacing: normal;

        button {
            background-color: #0064ff;
            color: #fff;
            position: relative;
            font-size: 12.5px;
            @include TMOBI500;
            // padding: 20px 50px;
            width: 80%;
            top: 150px;
            padding: 14px 0;
            border-radius: 8px;
            // margin-bottom: 30px;
        }
    }
}

.promotion {
    padding-bottom: 20px;
    // border-bottom: 1px solid #f1f1f1;
    button {
        z-index: 1;

        &.back-btn {
            width: 15px;
            height: 30px;
            margin: 20px;
        }
    }

    .main-info {
        margin-bottom: 40px;
    }

    .event-info {
        .main-header {
            text-align: center;

            span {
                background-color: #0064ff;
                @include TMOBI300;
                color: #fff;
                font-size: 13px;
                padding: 5px 20px;
                border-radius: 15px;
            }
        }

        .header-title {
            text-align: center;
            font-size: 20px;
            @include TMOBI500;
            color: #0064ff;
            letter-spacing: normal;
            margin-top: 20px;
        }

        .info {
            display: flex;
            justify-content: center;
            padding: 20px 0;

            .info-box {
                letter-spacing: normal;
                padding: 20px;
                position: relative;
                background-color: #F0F4FA;
                width: 85%;
                border-radius: 10px;

                li {
                    padding-bottom: 15px;

                    .list {
                        line-height: 35px;
                        @include TMOBI500;
                        font-size: 14px;
                    }

                    .li-content {
                        margin-top: 5px;
                        @include TMOBI300;
                        font-size: 12.5px;
                        color: #666666;

                        .bold-content {
                            color: #000;
                            @include TMOBI500;
                            font-size: 12.5px;
                        }
                    }
                }

                .info-sub {
                    @include TMOBI300;
                    font-size: 11px;
                    color: #072F6D;
                }
            }
        }

        .first {
            text-align: center;
            margin: 8px 0 20px 0;

            .first-comment {
                font-style: normal;
                font-size: 14.5px;
                line-height: 1.5;
                text-align: center;
                @include TMOBI300;
                color: #083D8D;
                // transform: rotate(0.16deg);
                padding: 30px 0 20px 0;
            }

            button {
                background-color: #072F6D;
                color: #fff;
                position: relative;
                font-size: 13px;
                @include TMOBI500;
                // padding: 20px 50px;
                width: 85%;
                padding: 14px 0;
                border-radius: 8px;
                margin-bottom: 30px;
            }
        }
    }

    .info-bottom {
        display: flex;
        justify-content: space-between;
        background-color: #F0F4FA;
        padding: 30px;
        @include TMOBI500;

        .drop-btn {
            padding: 10px;
        }
    }

    .info-content {
        background-color: #F0F4FA;
        padding: 0 20px 30px 20px;
        @include TMOBI300;
        color: #666;
        font-size: 11px;
        line-height: 25px;
        transition: 0.3s ease-out;
        overflow: hidden;

        .text-line {
            line-height: 18px;
            @include TMOBI300;
            color: #666;
            font-size: 11px;
            padding: 5px 0;
        }

        .list {
            padding-left: 10px;

            li {
                list-style: none;
                @include TMOBI300;
                color: #666;
                font-size: 11px;
                line-height: 20px;
            }
        }

        .bold-content {
            color: #666;
            @include TMOBI500;
        }
    }
}

.ev-delivery {
    button {
        position: absolute;

        &.back-btn {
            width: 30px;
            height: 30px;
            margin: 15px;
        }
    }

    .main-info {
        letter-spacing: normal;

        // margin-bottom: 30px;
        .first {
            text-align: center;

            button {
                background-color: #111;
                color: #fff;
                // left: 10%;
                // position: absolute;
                // bottom: 25%;
                // top:80%;
                // left:50%;
                // transform:translate(-50%,-65%);
                bottom: 90px;
                font-size: 13px;
                @include TMOBI500;
                position: relative;
                // padding: 20px 50px;
                width: 85%;
                padding: 14px 0;
                border-radius: 8px;
            }
        }
    }

    .third {
        padding: 40px 10px;
        margin: 0 15px 30px;
        background-color: #EBF3FF;
        @include TMOBI300;
        text-align: center;
        letter-spacing: normal;
        border-radius: 10px;

        .head-title {
            text-align: center;
            background: #111;
            @include TMOBI300;
            color: #fff;
            font-size: 16px;
            border-radius: 25px;
            margin: 0 auto;
            width: fit-content;
            padding: 8px 20px;
            letter-spacing: 0.03em;
        }
        .content-box{
            padding: 20px 0 10px;
            line-height: 1.8;
            .first-head{
                @include TMOBI300;
                font-size: 14px;
                letter-spacing: normal;
            }
            .first-content{
                @include TMOBI500;
                font-size: 18px;
                letter-spacing: normal;
                border-bottom: 3px solid #BED6FA;
            }
        }
        .sub-noti{
            color: #999;
            text-align: center;
            @include TMOBI300;
            font-size: 12px;
        }

        .inner-text {
            color: #666;
            @include TMOBI300;
            font-size: 13px;
            text-align: center;
            margin: 0 0 10px 0;
            letter-spacing: 0.03em;
        }

        .inner-text2 {
            @include TMOBI500;
            font-size: 17px;
            color: #0064ff;
            text-align: center;
            margin: 20px 0 10px 0;
            letter-spacing: 0.02em;
        }
    }

    .last {
        // background-color: #F0F5FD;
        @include TMOBI300;
        text-align: center;
        font-size: 15px;
        margin-top: 30px;
        letter-spacing: normal;

        .last-date {
            @include TMOBI500;
            text-align: center;
            font-size: 20px;
            color: #111;
        }

        button {
            background-color: #111;
            color: #fff;
            position: relative;
            font-size: 12.5px;
            @include TMOBI500;
            // padding: 20px 50px;
            width: 95%;
            padding: 14px 0;
            border-radius: 8px;
        }
    }
}

.free-ev-delivery{
    button{
        position: absolute;
        &.back-btn{
            width: 30px;
            height: 30px;
            margin: 15px;
        }
    }
    .main-info{
        letter-spacing: normal;
        background-color: #F0F4FA;
        .main-top{
            text-align: center;
            position: absolute;
            top: 10vh;
            width: 100%;
            left: 50%;
            transform: translate( -50%, -50% );
            .title{
                color: #FFF;
                @include TMOBI500;
                font-size: 5vw;
                font-style: normal;
                opacity: 0.6;
            }
            .border-box{
                background-color: #fff;
                color: #8527FF;
                border-radius: 5px;
                padding: 0 4px;
            }
        }
        .main-title{
            text-align: center;
            position: absolute;
            top: 20vh;
            width: 100%;
            left: 50%;
            transform: translate( -50%, -50% );
            color: #FFF;
            @include TMOBI500;
            // font-size: 35px;
            font-size: 9vw;
            line-height: 1.45;
            .border-box{
                background-color: #fff;
                color: #8527FF;
                border-radius: 5px;
                padding: 0 4px;
            }
        }
        .main-subtitle{
            text-align: center;
            position: relative;
            // top: 64vh;
            // width: 100%;
            // left: 50%;
            // transform: translate( -50%, -50% );
            bottom: 170px;
            color: #FFF;
            @include TMOBI300;
            // letter-spacing: 0.02em;
            font-size: 3.8vw;
            opacity: 0.8;
            line-height: 1.7;
            .bold-text{
                @include TMOBI500;

            }
        }
        .first{
            text-align: center;
            button{
                background-color: #111;
                color: #fff;
                bottom: 150px;
                font-size: 13px;
                @include TMOBI500;
                position: relative;
                // padding: 20px 50px;
                width: 80%;
                padding: 14px 0;
                border-radius: 8px;
            }
        }
    }
    .delivery{
        padding-bottom: 40px;
        background-color: #F0F4FA;
        @include TMOBI300;
        text-align: center;
        position: relative;
        top: -30px;
        .head-title{
            letter-spacing: normal;
            text-align: center;
            @include TMOBI300;
            color: #0064ff;
            // font-size: 5vw;
            font-size: 23px;
            padding: 8px 20px;
            letter-spacing: 0.05em;
            .bold{
                @include TMOBI500;
            }
        }
        .inner-text{
            color: #333;
            @include TMOBI300;
            font-size: 12px;
            line-height: 1.5;
            text-align: center;
            margin: 0 0 10px 0;
            letter-spacing: 0.03em;
        }
        .inner-text2{
            @include TMOBI500;
            font-size: 17px;
            color: #111;
            text-align: center;
            letter-spacing: 0.025em;
        }
    }
    .main-content{
        padding: 30px 10px;
        @include TMOBI300;
        text-align: center;
        letter-spacing: normal;
        // border-radius: 10px;
        .head-title{
            text-align: center;
            background: #333;
            @include TMOBI500;
            color: #fff;
            font-size: 14px;
            border-radius: 25px;
            margin: 0 auto;
            width: fit-content;
            padding: 8px 20px;
            letter-spacing: 0.035em;
        }
        .inner-text{
            color: #333;
            @include TMOBI300;
            font-size: 12px;
            text-align: center;
            margin: 0 0 10px 0;
            letter-spacing: 0.03em;
        }
        .inner-text2{
            @include TMOBI500;
            font-size: 16px;
            color: #333;
            text-align: center;
            margin: 20px 0 10px 0;
            letter-spacing: 0.02em;
            border-bottom: 3px solid #333;
            width: fit-content;
            margin: 0 auto;
            padding-top: 25px;
        }
        .sub-noti{
            color: #999;
            text-align: center;
            @include TMOBI300;
            font-size: 14px;
            padding-top: 15px;
        }
    }
    .downarrow{
        text-align: center;
    }
    .last{
        background: #F0F4FA;
        @include TMOBI300;
        text-align: center;
        font-size: 15px;
        margin-top: 30px;
        letter-spacing: normal;
        padding-top: 50px;
        .date{
            color: #111;
            text-align: center;
            @include TMOBI300;
            font-size: 18px;
            font-style: normal;
            .bold{
                @include TMOBI500;
            }
        }
        .last-date{
            @include TMOBI500;
            text-align: center;
            font-size: 25px;
            color: #111;
            padding: 5px 0 ;
            .highlight{
                background-color: #0064ff;
                color: #fff;
                border-radius: 5px;
                padding: 0 5px;
            }
        }
        .use{
            color: #111;
            @include TMOBI300;
            font-size: 15px;
        }
        button{
            background-color: #111;
            color: #fff;
            position: relative;
            font-size: 12.5px;
            @include TMOBI500;
            width: 80%;
            padding: 14px 0;
            border-radius: 8px;
            margin: 20px 0 30px;
        }
    }
}

.qr-charger {
    padding: 20px 0 100px;

    .multisession {
        padding: 5px 20px;
        position: relative;

        .charging-box {
            @include TMOBI300;
            padding: 10px 15px;
            margin-bottom: 10px;
            border: 1px solid #c6cad2;
            border-radius: 8px;
            line-height: 1.8;

            .auth-status {
                float: right;
                @include TMOBI300;
                // position: relative;
                // top: 10px;
                span{
                    padding: 3px;
                    border-radius: 5px;
                    font-size: 12px;
                }
            }
        }
    }

    .top-section {
        padding: 20px 20px 0px;
        .finish-title{
            @include TMOBI500;
            color: #171819;
            letter-spacing: normal;
            font-size: 24px;
            line-height: 1.9;
            .kwh{
                @include TMOBI300;
                color: #51565C;
                letter-spacing: normal;
                font-size: 16px;
            }
        }
        .warring-txt{
            @include TMOBI300;
            color: #8C9299;
            letter-spacing: normal;
            font-size: 16px;
            line-height: 1.9;
            padding-top: 20px;
        }
    }
    .credit-box{
        display: flex;
        justify-content: space-between;
        @include TMOBI300;
        color: #171819;
        letter-spacing: normal;
        font-size: 16px;
        padding: 10px 20px 20px;
        .selected{
            .card{
                @include TMOBI300;
                color: #171819;
                font-size: 16px;
                letter-spacing: normal;
            }
        }
        .pay-price{
            color: #0064ff;
            @include TMOBI500;
        }
    }
    .info-list{
        padding: 0 20px 20px;
        line-height: 1.7;
        li{
            color: #8C9299;
            @include TMOBI300;
            font-size: 12px;
            letter-spacing: normal;
            padding: 2px 0;
        }
    }

    .progress-bar{
        // width: 90%;
        padding: 0 20px;
        margin: 0 auto;
    }

    .low-charger-btn {
        display: block;
        font-size: 13px;
        line-height: 1.57;
        color: #111;
        text-align: center;
        padding: 16px 20px;
        border-radius: 8px;
        background-color: #fff;
        @include TMOBI300;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #e55545;
    }
}

.button-box {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 20px 20px;
    width: 100%;
    background: #fff;
    z-index: 9;

    &:before{
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    a {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 50px;
        color: #fff;
        background: #0064ff;
        border-radius: 8px;
    }

    button {
        display: block;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 50px;
        color: #fff;
        background: #0064ff;
        border-radius: 8px;
    }
}

.dim-button-box {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 20px 20px;
    width: 100%;
    background: #fff;
    z-index: 9;

    &:before{
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    a {
        visibility: hidden;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 0;
        color: #BEC5CC;
        background: #F8F9FA;
        border-radius: 8px;
        pointer-events: none;

        &:after {
            visibility: visible;
            content: "충전 종료 중입니다";
            display: block;
            width: 100%;
            text-align: center;
            font-size: 17px;
            @include TMOBI500;
            line-height: 50px;
            color: #BEC5CC;
            background: #F8F9FA;
            border-radius: 8px;
            pointer-events: none;
        }
    }
}

.dim-button-finish{
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 20px 20px;
    width: 100%;
    background: #fff;
    z-index: 9;

    &:before{
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    button {
        display: block;
        width: 100%;
        line-height: 50px;
        border-radius: 8px;
        text-align: center;
        color: #BEC5CC;
        height: 50px;
        background: #F8F9FA;
        font-size: 17px;
        @include TMOBI500;
    }
}

.wait-button-box {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 20px 20px;
    width: 100%;
    background: #fff;
    z-index: 9;

    &:before{
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    a {
        visibility: hidden;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 0;
        color: #BEC5CC;
        background: #F8F9FA;
        border-radius: 8px;
        pointer-events: none;

        &:after {
            visibility: visible;
            content: "충전 종료하기";
            display: block;
            width: 100%;
            text-align: center;
            font-size: 17px;
            @include TMOBI500;
            line-height: 50px;
            color: #BEC5CC;
            background: #F8F9FA;
            border-radius: 8px;
            pointer-events: none;
        }
    }
}

/* background: url(../src/assets/images/ic-card-default.svg) no-repeat; */

.pay-infobox {
    margin-top: 34px;

    dl {
        padding: 0 20px;

        dt {
            @include TMOBI300;
            color: #8C9299;
            line-height: 24px;
        }

        dd {
            text-align: right;
            padding: 15px 0 15px 60px;
            font-size: 16px;

            &.border {
                border-bottom: 1px solid #EEF0F3;
                color: #111;
            }
        }

        .pay-coupon-box {
            padding-top: 12px;

            .pay-dd {
                text-align: right;
                padding: 10px 0;
                font-size: 16px;
                @include TMOBI300;
            }
        }

        .rateCoupon {
            /* padding-top: 15px; */
            font-size: 14px;
            @include TMOBI300;
            color: #0064ff;
            line-height: 24px;

            /* width: 90px; */
            word-break: keep-all;
            position: absolute;
            padding: 5px 0 0;

            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 9px;

                /* right: -3px; */
                width: 16px;
                height: 16px;
                background: url(../../assets/images/common/icon_common_arrow_20.png) no-repeat;
                background-size: cover;
            }
        }
    }

    .pay-coupon-box .pay-btn,
    .coupon-btn {
        padding-top: 9px;
        font-size: 14px;
        @include TMOBI300;

        /* color: #0064ff; */
        line-height: 24px;

        /* width: 90px; */
        word-break: keep-all;
        position: absolute;

        /* padding: 5px 0 0; */
    }

    dl {
        .pay-coupon-box .pay-btn:after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 12px;

            /* right: -3px; */
            width: 16px;
            height: 16px;
            background: url(../../assets/images/common/icon_common_arrow_20.png) no-repeat;
            background-size: cover;
        }

        .rateCoupon dd {
            font-size: 15px;
            color: #a1a3a6;
            padding: 5px 0 5px 120px;
        }

        dd {
            .noUse {
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include TMOBI300;
                color: #a1a3a6;
                line-height: 24px;
                text-align: left;
                display: inline-block;
                max-width: 100%;
            }

            p {
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include TMOBI500;

                color: #0064ff;
                line-height: 24px;
                text-align: left;
                display: inline-block;
                max-width: 100%;

                i {
                    position: relative;
                    margin-right: 5px;
                    display: inline-block;
                    width: 24px;

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                &.icon01 {
                    padding-left: 24px;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url(../../assets/images/common/ic_map_evstation_blue.svg) no-repeat;
                        background-size: cover;
                    }
                }
            }
        }

        &.total-box {
            padding: 10px 20px;

            dt {
                font-size: 16px;
                // padding-top: 5px;

                &.last {
                    // width: 120px;
                    width: fit-content;
                    padding-top: 16px;
                    @include TMOBI500;
                    font-size: 18px;
                    line-height: 26px;
                    color: #111;
                }
            }

            dd {
                font-size: 16px;

                /* color: #a1a3a6; */
                padding: 0px 0 2px 120px;

                p {
                    font-size: 16px;

                    /* color: #a1a3a6; */
                    @include TMOBI300;
                }

                &.last {
                    padding: 10px 0 0 140px;

                    p {
                        font-size: 24px;
                        line-height: 34px;

                        /* color: #0064ff; */
                        @include TMOBI500;
                    }
                }
            }
        }
    }
}

.picture-min {
    height: 70px;
    background-size: cover;
    display: inline;
    position: absolute;
    -webkit-appearance: none;
    top: -80px;
    right: 8px;
    width: 180px;

    img {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        width: fit-content;
        // left: 50%;
        // display: block;
        // width: 45%;
    }
}

// .button-list-group-easy {
//     // position:fixed;
//     // bottom:0;
//     left: 0;
//     width: 100%;
//     padding: 0 20px;
//     background: #fff;
//     z-index: 1000;
//     border-radius: 10px;
.destination{
    padding-bottom: 30%;
    .qr-title-group {
        padding: 0 20px 10px;
        display: flex;
        flex-direction: column;

        .go-info{
            color: var(--gray-color-gray-900, #171819);
            font-size: 15px;
            @include TMOBI300;
        }

        // display: flex;
        // flex-wrap: wrap;
        .station-name {
            position: relative;
            @include TMOBI500;
            font-size: 20px;
            line-height: 1.44;
            color: #171819;
            letter-spacing: 0.001em;
            padding: 5px 0;
        }

        span {
            @include TMOBI300;
            font-size: 14px;
            line-height: 1.54;
            color: #8C9299;
            letter-spacing: normal;
        }
    }

    .destination-list {
        padding: 12px 20px;

        .destination-ment {
            @include TMOBI300;
            position: relative;
            padding: 15px 12px;
            border-radius: 8px;
            background-color: #F8F9FA;
            font-size: 14px;
            color: #6E747A;

            &:after {
                content: "";
            }

            span {
                @include TMOBI300;
                font-size: 13px;
                color: #666666;
                float: right;

                &:before {
                    content: "";
                    position: absolute;
                    // left: 0;
                    right: 49px;
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    background: url(../../assets/images/common/ic-info.svg) no-repeat;
                    background-size: cover;
                }
            }

        }
    }
    ul {
        margin-top: 16px;

        li {
            position: relative;
            padding: 20px 16px;
            border-radius: 10px;
            border: solid 1px #EEF0F3;
            background-color: #fff;
            display: flex;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);

            em {
                position: relative;
                @include TMOBI300;
                display: inline-block;
                width: 42px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                font-size: 20px;
                border-radius: 4px;
                overflow: hidden;
            }

            .txt {
                margin-left: 12px;

                .dc {
                    font-size: 0;

                    span {
                        position: relative;
                        display: inline-block;
                        padding-left: 18px;
                        font-size: 14px;
                        @include TMOBI300;

                        // color: #222;
                        &+span {
                            margin-left: 6px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                        }

                        &.type1 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-1.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type2 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-2.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type3 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-3.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type4 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-4.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type5 {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-5.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type1_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-1-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type2_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-2-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type3_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-3-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }

                        &.type4_dim {
                            &:before {
                                background: url(../../assets/images/coupler/ic-list-charging-type-4-disabled.svg) no-repeat;
                                background-size: cover;
                            }
                        }
                    }
                }

                .info {
                    margin-top: 4px;
                    font-size: 0;

                    span {
                        position: relative;
                        display: inline-block;
                        @include TMOBI300;
                        font-size: 12px;
                        color: #909090;

                        &+span {
                            margin-left: 13px;
                        }

                        &:before {
                            content: "";
                            display: inline-block;
                            width: 1px;
                            height: 12px;
                            background: #e0e0e0;
                            position: absolute;
                            left: -6px;
                            top: 3px;
                        }

                        &:first-child {
                            &:before {
                                display: none;
                            }
                        }

                        &.kw {
                            padding-left: 16px;

                            &:after {
                                content: "";
                                position: absolute;
                                left: 0;
                                width: 16px;
                                height: 16px;
                                margin: 0 -10px -10px -0;
                            }

                            &.type1 {
                                &:after {
                                    background: url(../../assets/images/common/ic-evcharger-rapid-01-disabled.svg) no-repeat;
                                    background-size: cover;
                                }
                            }

                            &.type2 {
                                &:after {
                                    background: url(../../assets/images/common/ic-evcharger-rapid-02-disabled.svg) no-repeat;
                                    background-size: cover;
                                }
                            }

                            &.type3 {
                                &:after {
                                    background: url(../../assets/images/common/ic-evcharger-rapid-gray-01-disabled.svg) no-repeat;
                                    background-size: cover;
                                }
                            }
                        }
                    }
                }
            }

            .time {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 13.5px;
                @include TMOBI300;

                &.color1 {
                    color: #0064ff;
                }

                &.color2 {
                    color: #909090;
                }

                &.color3 {
                    color: #e55545;
                }

                &.color4 {
                    color: #d8d8d8;
                }
            }

            &+li {
                margin-top: 10px;
            }

            &.finish {
                em {
                    border: 1px solid #c6cad2;
                    line-height: 33px;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 8px;
                        background-image: linear-gradient(to right, #ff4db7, #0064ff 34%, #00daf0 71%, #32e6af);
                    }
                }
            }
        }
    }
}


.destination-nocharge {
    @include TMOBI300;
    font-size: 15px;
    color: #000;
    margin-bottom: 2px;
    z-index: 9999999;
    text-align: center;
    margin-top: 50px;
    line-height: 1.5;
}

.red-dot{
    padding:4px;
    background-color:red;
    position:absolute;
    top:7px;
    border-radius:50%;
    left:37px
}


.tap-header {
    display: block;
    top: 0;
    position: sticky;
    z-index: 999;
    .top {
        background-color: #fff;
        width: 100%;
        height: 56px;
        display: flex;
        justify-content: space-between;
        .charge-info{
            @include TMOBI300;
            font-size: 15.5px;
            color: #171819;
            top: 15px;
            right: 20px;
            position: relative;
        }
    }

    .picture {
        // height: 152px;
        background-size: cover;
        // display: inline;

        img {
            display: block;
            width: 100%;
        }
    }
}

.charge-history-list{
    padding: 15px 20px;

    .history-list-box{
        display: flex;
        // padding-top: 20px;
        line-height: 1.9;
        .time{
            @include TMOBI300;
            font-size: 13px;
            top: 1px;
            color: #6E747A;
            position: relative;
        }
        .inner-text{
            padding-left: 10px;
            .station-txt{
                @include TMOBI300;
                font-size: 16px;
                color: #6E747A;
                letter-spacing: normal;
            }
            .addr-txt{
                @include TMOBI300;
                font-size: 12px;
                color: #6E747A;
                letter-spacing: normal;
            }
        }
    }
}

.history-kwh-txt{
    display: flex;
    justify-content: space-between;
    .kwh-txt{
        padding: 10px 0 0 41px;
        @include TMOBI300;
        font-size: 14px;
        color: #171819;
        letter-spacing: normal;
    }
    .price{
        padding-top: 10px;
        @include TMOBI500;
        font-size: 16px;
        color: #171819;
        letter-spacing: normal;
    }
    .price::after{
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(../../assets/images/common/icon-common-arrow.svg) no-repeat;
        position: relative;
        top: 4px;
        left: 5px;
    }
}
.charging-progress{
    display: flex;
    justify-content: space-between;
    @include TMOBI300;
    font-size: 16px;
    color: #171819;
    letter-spacing: normal;
    padding: 20px;
    p{
        @include TMOBI300;
        padding-right: 10px;
    }
}

.charge-info-box{
    border-top: 7px solid #F8F9FA;
    .charge-info{
        display: flex;
        justify-content: space-between;
        @include TMOBI300;
        font-size: 16px;
        color: #171819;
        letter-spacing: normal;
        padding: 20px;
        .head-value{
            color:#8C9299
        }
    }
    .info-list{
        padding: 0 20px 20px;
        line-height: 1.7;
        li{
            color: #8C9299;
            @include TMOBI300;
            font-size: 12px;
            letter-spacing: normal;
            padding: 2px 0;
        }
    }
}


.coupon-contents {
    .coupon-guide-bottom {
        padding: 0 20px;
        // margin: 0 20px;
        font-size: 12px;
        color: #8C9299;
        text-align: justify;
        line-height: 1.5;
        .guide-list {
            display: flex;
            img {
                align-self: flex-start;
            }
            p {
                @include TMOBI300;
                font-size: 12px;
                line-height: 18px;
                padding-bottom: 5px;
                letter-spacing: normal;
            }
        }

    }

    .coupon-section {
        padding: 40px 20px 40px;
        .coupon-list {
            border: 1px solid var(--gray-color-gray-300, #E0E2E8);
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
            padding: 20px;
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            z-index: 9;
            position: relative;
            // flex-direction: column;

            .seperate {
                display: flex;

                .coupon-info-section {
                    @include TMOBI300;
                    padding-left: 20px;
                    line-height: 1.6;
                    letter-spacing: normal;

                    .coupon-point {
                        font-size: 18px;
                        @include TMOBI500;
                    }

                    .coupon-history-section {
                        color: #8C9299;
                        font-size: 12.5px;
                    }
                }
            }
            .pc-deactive{
                display: flex;
                padding: 5px 0;
                .title{
                    @include TMOBI500;
                    display: flex;
                    justify-content: space-between;
                    font-size: 18px;
                    letter-spacing: normal;
                    align-items: center;
                    margin-left: 20px;
                    .active-btn {
                        @include TMOBI300;
                        font-size: 13.5px;
                        color: #0064FF;
                        background-color: #e6f1ff;
                        padding: 8px 12px;
                        border-radius: 20px;
                        height: fit-content;
                        position: absolute;
                        right: 20px;
                    }
                }
            }
            .pc-info{
                display: flex;
                background-color: #eef0f3;
                padding: 10px;
                border-radius: 10px;
                margin-top: 15px;
                justify-content: space-between;

                .info-ico{
                    width: 18px;
                    height: 18px;
                }
                .info{
                    @include TMOBI300;
                    font-size: 12.5px;
                    margin-left: 10px;
                }
            }

            input[type=checkbox] {
                display: none;
            }

            input[type=checkbox]+label {
                width: 22px;
                position: absolute;
                height: 22px;
                background: url('../../assets/images/coupon/ico_uncheck.svg') no-repeat;
            }

            .coupon-checkbox,
            .coupon-checkbox-off {
                padding: 30px;
                z-index: inherit
            }

            .coupon-checkbox input[type=checkbox]:checked+label {

                width: 22px;
                // margin-right: 15px;
                position: absolute;
                height: 22px;
                background: url('../../assets/images/coupon/ico_checked.svg') no-repeat;
            }
        }
    }
}

.coupon-history {
    .list-box {
        .coupon-use-date{
            @include TMOBI500;
            font-size: 14px;
            color: #6E747A;
            letter-spacing: normal;
            padding-top: 50px;
        }
        .coupon-history-list {
            // border-bottom: 1px solid #E0E2E8;
            padding: 15px 0 20px;
            line-height: 1.7;

            .time {
                @include TMOBI300;
                font-size: 14px;
                color: #6E747A;
            }

            .addr-txt {
                @include TMOBI300;
                font-size: 16px;
                color: #171819;
                display: flex;
                justify-content: space-between;

                .price {
                    @include TMOBI500;
                    color: #0064ff;
                }
            }
        }
    }

    .coupon-info {
        display: flex;
        flex-direction: column;
        line-height: 1.7;
        letter-spacing: normal;

        .coupon-name {
            @include TMOBI500;
            font-size: 16px;
            color: #171819;
        }

        .coupon-point {
            @include TMOBI500;
            font-size: 24px;
            color: #222;
        }

        .coupon-expire {
            @include TMOBI300;
            font-size: 14px;
            color: #A1A3A6;
        }
    }
}

.remote-finish{
    background: var(--semantic-color-orange-200, #FFF0D9);
    width: 90%;
    margin: 0 auto;
    padding : 15px;
    margin-top: 20px;
    border-radius: 8px;
    display: flex;
    .remote-title{
        @include TMOBI300;
        font-size: 16px;
        color: #51565C;
        margin-left: 8px;
        letter-spacing: normal;
        .sub-title{
            color: #8C9299;
            font-size: 12px;
        }
    }
}

.charger-guide {
    padding: 100px 25px;
    // height: 100%;
    // background: url(../../assets/images/logo/img_info_evstastion.svg) center bottom no-repeat;
    background-size: 100%;

    .info-header{
        font-size: 24px;
        @include TMOBI500;
        line-height: 34px;
    }
    .info-body{
        padding-top: 40px;
        .info-box{
            // line-height: 1.8;
            padding: 22px 0;
            letter-spacing: normal;
            .title{
                font-size: 18px;
                @include TMOBI500;
                color: #171819;
                padding-bottom: 12px;
            }
            .ment{
                font-size: 14.5px;
                @include TMOBI300;
                color: #51565C;
            }
            li{
                font-size: 12px;
                @include TMOBI300;
                color: #8C9299;
                padding-top: 10px;
            }
        }
    }
}

.polestar-guide {
    padding-top: 160px;

    .guide-header {
        font-family: 'TMOBI500';
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        margin: 0 20px;
        width: 80%;
    }

    .guide-body {
        .guide-step {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            border-radius: 12px; /* Spacing/spacing-150에 해당 */
            margin-top: 20px; /* 스텝 간의 상단 여백 */
            margin-left: 0; /* 왼쪽으로 당김 */
            padding: 10px 20px; /* 패딩 조정: 좌우 20px */
            margin-bottom: 10px;


            .step-header {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .step-number {
                    font-family: 'TMOBI300';
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 64px; /* 고정 너비 */
                    height: 24px; /* 고정 높이 */
                    padding: 0 8px; /* 내부 여백 */
                    border-radius: 12px / 12px;
                    background-color: #E6F1FF; /* Step 배경색 */
                    color: #0064FF; /* Step 글자색 */
                    font-size: 14px;
                    line-height: 20px; /* 줄 간격 */
                    text-align: center; /* 텍스트 중앙 정렬 */
                }

                .step-title {
                    font-family: 'TMOBI500';
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 24px;
                    color: #171819;
                    margin-left: 5px;
                }
            }
            .step-middle-description {
                font-family: 'TMOBI300';
                font-size: 16px;
            }

            .step-description {
                font-family: 'TMOBI300';
                font-size: 14px;
                color: #51565C;
                line-height: 22px; // 20 -> 22로 변경
            }

            .step-comments {
                font-family: 'TMOBI300';
                font-size: 14px;
                color : #8C9299
            }
            .text-container {
                display: flex;
                justify-content: center; /* 전체 아이템을 가운데 정렬 */
                align-items: center; /* 세로 가운데 정렬 */
                width: 100%; /* 부모 요소에 맞게 가로 길이 설정 */
                max-width: 800px; /* 최대 너비 제한 */
                padding: 10px 20px; /* 좌우 여백 추가 */
                margin: 0 auto; /* 가운데 정렬 */
                box-sizing: border-box; /* 패딩을 포함한 전체 너비를 계산하도록 설정 */
            }

            .text-wrapper {
                display: flex;
                align-items: center; /* 세로 가운데 정렬 */
                gap: 80px; /* 기본 간격 */
            }

            .text-item {
                font-size: 16px; /* 적절한 글자 크기 */
                font-weight: bold; /* 굵은 글씨 */
                font-family: 'TMOBI300';
            }

            .second-item {
                transform: translateX(20px); /* 두 번째 아이템을 오른쪽으로 이동 */
            }
        }
        .guide-image {
            display: block; /* 이미지 주변의 inline 여백 제거 */
            margin: 0; /* 모든 방향의 마진 제거 */
            width: 100%; /* 부모 요소 너비에 맞추기 */
            max-width: 100%; /* 부모 요소를 넘어가지 않도록 제한 */
            height: auto; /* 비율 유지 */
            border: none; /* 경계선 제거 */
            padding: 0; /* 패딩 제거 */
            box-sizing: border-box; /* 패딩과 보더를 포함한 전체 너비 계산 */
            object-fit: cover; /* 이미지가 부모 요소에 맞도록 커버 */
        }

        .footer-image {
            position: absolute;
            top: 718px; /* 화면 상단에서 718px 아래로 배치 */
            left: 50%;
            transform: translateX(-50%); /* 가로축 중앙 정렬 */
            text-align: center;
            margin: 0;
        }

        .footer-logo {
            width: 60px; /* 이미지 너비 */
            height: 54px; /* 이미지 높이 고정 */
            display: block;
            margin: 0 auto; /* 상하 0, 좌우 자동으로 중앙 정렬 */
            height: auto;
        }
    }
}

.charger-top-section{
    display: flex;
    justify-content: space-between;
    padding: 15px;
    top: 0;
    position: sticky;
    background-color: #fff;
    z-index: 9;
    // &:after{
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     display: block;
    //     width: 100%;
    //     top: 45px;
    //     height: 50px;
    //     background: linear-gradient(180deg, #FFF 37.5%, rgba(255, 255, 255, 0) 100%);

    // }

    img{
        width: 30px;
    }
}

.spharos-promotion {
    .back-btn {
        position: absolute;
        width: 30px;
        height: 30px;
        margin: 15px;
    }
    .coupon {
        text-align: center;
        padding: 20px 0;
        .coupon-btn {
            background-color: #111;
            color: #fff;
            font-size: 13px;
            @include TMOBI500;
            position: relative;
            width: 80%;
            padding: 16px 20px;
            border-radius: 8px;
        }
    }
}

.main-banner-box{
    
    // display: flex;
    background-color: rgb(230, 241, 255);
    /* margin-top: 60px; */
    // top: 65px;
    padding: 13px 20px;
    // margin: 0 20px;
    position: relative;
    justify-content: space-between;
    // 
}

.main-banner-box2{
    // display: flex;
    
    background-color: #F8F9FA;
    /* margin-top: 60px; */
    // top: 65px;
    padding: 13px 20px;
    // margin: 0 20px;
    position: relative;
    justify-content: space-between;
    // 
}

.main-banner-box3{
    
    // display: flex;
    background-color: #F1FBFB;
    /* margin-top: 60px; */
    // top: 65px;
    padding: 13px 20px;
    // margin: 0 20px;
    position: relative;
    justify-content: space-between;
    // border-radius: 4px;
}

.main-banner-box4{
    
    // display: flex;
    background-color: #F8F9FA;
    /* margin-top: 60px; */
    // top: 65px;
    padding: 13px 20px;
    // margin: 0 20px;
    position: relative;
    justify-content: space-between;
    // border-radius: 4px;
}
.main-banner-box5{
    // display: flex;
    background-color: #F8F9FA;
    /* margin-top: 60px; */
    // top: 65px;
    padding: 12px 45px;
    // margin: 0 20px;
    position: relative;
    justify-content: space-between;
    // border-radius: 4px;
}

.main-banner-box6{
    // display: flex;
    background: linear-gradient(302deg, #830E89 -53.02%, #000000 88.04%);
    /* margin-top: 60px; */
    // top: 65px;
    padding: 13px 20px;
    // margin: 0 20px;
    position: relative;
    justify-content: space-between;
    // border-radius: 4px;
}

.main-banner-box7{
    background: linear-gradient(302.4deg, #EDC675 -53.02%, #DC440E 88.04%);
    padding: 13px 20px;
    position: relative;
    justify-content: space-between;
}

.banner-header{
    @include TMOBI500;
    font-size: 15px;
    color: #171819;
}

.banner-header2{
    @include TMOBI500;
    font-size: 15px;
    color: #171819;
    &::before {
        content: "";
        display: inline-block;
        width: 20px; // 아이콘 크기 설정
        height: 20px;
        background-image: url('../../assets/images/common/ic_info_blue.svg'); // 아이콘 경로
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 18px; // 텍스트 앞에 위치하도록 설정
        top: 12px;

    }
}

.banner-header3{
    @include TMOBI300;
    font-size: 12px;
    color: #BEC5CC;
}

.banner-header4{
    @include TMOBI500;
    font-size: 15px;
    color: #FFFFFF;
}

.banner-content{
    @include TMOBI300;
    font-size: 12px;
    color: #51565C;
}

.banner-content2{
    @include TMOBI500;
    font-size: 15px;
    color: #FFFFFF;
}

.banner-content3{
    @include TMOBI300;
    font-size: 12px;
    color: #F7F7F7;
}

.pin-marker-select {
    position:relative;
    padding: 5px 10px;
    width:fit-content;
    // height:30px;
    color: #FFF;
    border-radius: 10px;
    background-color: #36393D;
    border : 1px solid #171819;
    @include TMOBI500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    .gr {
        background-color: #36393D;
    }
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 41%;
        width: 16px;
        height: 11px;
        background: url(../../assets/images/common/black-pin.svg) no-repeat;
        background-size: cover;
        z-index: 9;
    }
    .fast{
        padding-right: 10px;
    }
    .pnc-tag {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 20px;
        border-radius: 2px;
        background: var(--Secondary-color-Gradient-C, linear-gradient(93deg, #0064FF 12.22%, #34C7C2 111.73%));
        top: -19px;
        p {
            color: #fff;
            text-align: center;
            @include TMOBI500;
            font-size: 11px;
            line-height: 16px;
            padding: 0 4px;
            white-space: nowrap;
        }
    }
}


.pin-marker {
    // background: url(../../assets/images/common/pin-test.png) no-repeat;
    position:relative;
    padding: 5px 10px;
    width:fit-content;
    // height:30px;
    color: #171819;
    border-radius: 10px;
    background-color: #fff;
    border : 1px solid #BEC5CC;
    @include TMOBI500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    .gr {
        background-color: #fff;
    }
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 41%;
        width: 16px;
        height: 11px;
        background: url(../../assets/images/common/white-pin.svg) no-repeat;
        background-size: cover;
        z-index: 9;
    }
    .fast{
        padding-right: 10px;
    }
    .pnc-tag {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 20px;
        border-radius: 2px;
        // border: 1px solid var(--Secondary-color-Gradient-C, #0064FF);
        background: var(--Secondary-color-Gradient-C, linear-gradient(93deg, #0064FF 12.22%, #34C7C2 111.73%));
        top: -19px;
        p {
            color: #fff;
            text-align: center;
            @include TMOBI500;
            font-size: 11px;
            line-height: 16px;
            padding: 0 4px;
            white-space: nowrap;
        }
    }
}

.and-before {
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 27px;
        right: 30%;
        width: 30px;
        height: 3px;
        z-index: 10;
        background-color: #fff;
    }
}

.and-short-before {
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 27px;
        right: 30%;
        width: 23px;
        height: 3px;
        z-index: 10;
        background-color: #fff;
    }
}

.and-before-select {
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 27px;
        right: 30%;
        width: 30px;
        height: 3px;
        z-index: 10;
        background-color: #36393D;
    }
}

.and-short-before-select {
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 27px;
        right: 30%;
        width: 23px;
        height: 3px;
        z-index: 10;
        background-color: #36393D;
    }
}

.short{
    &:after {
        right: 29.5%;
    }
}

.t-short{
    &:after {
        right: 35.5%;
    }
}

.long-marker{
    &:after {
        right: 39.5%;
    }
}

.charge-history{
    .cancel-header{
        background-color: #E6F1FF;
        border-radius: 8px;
        padding: 20px;
        margin: 20px 15px 10px;
        @include TMOBI300;
        color: #51565C;
        font-size: 16px;
        .cancel-content{
            @include TMOBI300;
            color: #8C9299;
            font-size: 12px;
            padding-top: 5px;
            letter-spacing: normal;
        }
    }
}

.maintenance {
    .header {
        width: 100%;
        height: 56px;
        position: relative;
        justify-content: center;
        text-align: center;
        img {
            width: 30px;
            position: relative;
            left: 10px;
            float: left;
            top: 13px;
        }
        span {
            @include TMOBI500;
            font-size: 18px;
            color: #111;
            letter-spacing: normal;
            text-align: center;
            position: relative;
            top: 15px;
            right: 20px;
        }
    }
    .content {
        justify-content: center;
        text-align: center;
        margin-top: 80px;
        img {
            width: 64px;
            height: 64px;
        }
        .big-content {
            @include TMOBI500;
            font-size: 18px;
            text-align: center;
            letter-spacing: normal;
            margin-top: 32px;
        }
        .small-content {
            font-size: 16px;
            @include TMOBI300;
            text-align: center;
            letter-spacing: normal;
            margin-top: 8px;
            color: #8C9299;
            line-height: 1.5;
        }
        button {
            font-size: 16px;
            margin-top: 24px;
            height: 40px;
            padding: 0px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            border: solid 1px #BEC5CC;
            color: #171819;
        }
    }
}


.ev-repay {
    padding-bottom: 10px;
    button {
        position: absolute;

        &.back-btn {
            width: 30px;
            height: 30px;
            margin: 15px;
        }
    }

    .main-info {
        letter-spacing: normal;

        .inline-text {
            position: absolute;
            z-index: 1;
            top: 80px;
            left: 25px;
            color: #fff;
            width: max-content;
            line-height: 1.45;

            .border-text {
                @include TMOBI500;
                border: 2px solid #fff;
                border-radius: 30px;
                padding: 6px 10px;
                font-size: 3vw;
                .icon{
                    position: relative;
                    top: 3.5px;
                    width: 2.2vw;
                    left: 3px;
                }
            }

            .main-text {
                @include TMOBI700;
                font-size: 8vw;
                top: 20px;
                position: relative;
            }

            .sub-text{
                @include TMOBI300;
                top: 40px;
                font-size: 3.5vw;
                position: relative;
                letter-spacing: normal;
                line-height: 1.8;
                .highlight{
                    @include TMOBI500;
                    background-color: #002F73;
                    padding: 3px 4px;
                    border-radius: 5px;
                }
            }
            .info-msg{
                @include TMOBI300;
                top: 25vw;
                position: relative;
                letter-spacing: normal;
                .info1{
                    @include TMOBI500;
                    color: #26FFF2;
                    font-size: 3vw;
                }
                .info2{
                    @include TMOBI300;
                    // color: #26FFF2;
                    font-size: 2.5vw;
                    opacity: 0.8;
                    top: 8px;
                    position: relative;
                }
            }
        }
    }

    .repay-content {
        margin: 35px 20px;
        background-color: #EEF1F5;
        text-align: center;
        letter-spacing: normal;
        padding: 30px 0;
        border-radius: 8px;
        .content-header{
            @include TMOBI300;
            color: #111;
            font-size: 4vw;
            letter-spacing: normal;
            .highlight{
                @include TMOBI500;
                color: #0064FF;
            }
        }
        .title{
            @include TMOBI300;
            color: #111;
            font-size: 3.5vw;
            padding: 20px 0 15px;
        }
        img{
            width: 80%;
        }
    }
}

.pay-toggle-control {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    user-select: none;
    top: 3px;
    display: flex;
    align-items: center;

    input {
      opacity: 0;
      height: 0;
      width: 0;

      &:checked ~ .control {
        background-color: #0064FF;

        &:after {
          left: 13px;
          border: 1.5px solid #0064ff;
        }
      }
    }

    .control {
      position: absolute;
      right: 0;
      height: 15px;
      width: 33px;
      border-radius: 90px;
      background-color: #BEC5CC;
      transition: background-color .15s ease-in;
      top: 15px;

      &:after {
        content: "";
        position: absolute;
        left: -2px;
        top: -4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        transition: left .15s ease-in;
        border: 1.5px solid #BEC5CC;
      }
    }

    .uncontrolled {
        position: absolute;
        right: 0;
        height: 15px;
        width: 33px;
        border-radius: 90px;
        background-color: #EEF0F3;
        transition: background-color .15s ease-in;
        top: 15px;

        &:after {
          content: "";
          position: absolute;
          left: -2px;
          top: -4px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #F8F9FA;
          transition: left .15s ease-in;
          border: 1.5px solid #EEF0F3;
        }
      }
  }

.point-info{
    padding: 20px;
    border: 1px solid #BEC5CC;
    position: absolute;
    z-index: 9;
    background: #FFF;
    margin: 20px;
    border-radius: 4px;
    // top:41%;
    line-height: 1.6;
    letter-spacing: normal;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);

    .title{
        @include TMOBI500;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .info-content{
        text-indent: -6px;
        @include TMOBI300;
        font-size: 13px;
        padding-top: 5px;
        color: #8C9299;
        width: 90%;
        left: 10px;
        position: relative;
        .point-use{
            color:#3D8BFF;
            border-bottom: 1px solid #3D8BFF;
        }
        .info{
            @include TMOBI300;
        }
    }
}

.pay-info {
    padding-bottom: 30px;

    .inner-box {
        dl {
            margin-top: 10px;

            dt {
                @include TMOBI300;
                // padding: 20px 20px 0;
                font-size: 18px;
                color: #111;
                width: 150px;
                min-width: max-content;
            }

            dd {
                @include TMOBI500;
                // padding: 22px 20px 22px 130px;
                font-size: 18px;
                text-align: right;

                button {
                    @include TMOBI300;
                    position: relative;
                    display: inline-block;
                    color: #F24724;
                    font-size: 16px;
                    // padding-right: 20px;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    // top: -3px;
                }
            }
        }
        .t-point-section{
            display: flex;
            justify-content: space-between;
            padding: 20px;

            .t-point-header{
                @include TMOBI300;
                font-size: 18px;
                top: -5px;
                position: relative;
                .info-img{
                    position: relative;
                    top: 6px;
                    left: 3px;
                }
            }
            .t-point-body{
                position: relative;
                top: -13px;
                .point-content {
                    @include TMOBI300;
                    font-size: 16px;
                    position: relative;
                    top: 12px;
                    right: 40px
                }
                .remainP{
                    color: #8C9299;
                    top: 10px;
                    letter-spacing: normal;
                }
            }
        }

        .remainP {
            @include TMOBI300;
            position: relative;
            display: block;
            color: #0064ff;
            font-size: 12px;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: end;
            margin-top: 2px;
        }

        .noCoupon {
            @include TMOBI300;
            position: relative;
            display: inline-block;
            color: #8C9299;
            font-size: 16px;
            // padding-right: 20px;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        dl {
            dd {
                button {
                    i {
                        position: relative;
                        top: 2px;
                        margin-right: 5px;
                        display: inline-block;
                        width: 24px;

                        img {
                            display: block;
                            width: 100%;
                        }
                    }

                    // &:after {
                    //     content: "";
                    //     display: inline-block;
                    //     position: absolute;
                    //     top: 1px;
                    //     right: -3px;
                    //     width: 20px;
                    //     height: 20px;
                    //     background: url(../../assets/images/common/ico_arrow_right.png) no-repeat;
                    //     background-size: cover;
                    //     background-color: #fff;
                    // }
                }

                &.selected {
                    color: #111;

                    button {
                        color: #111;
                    }
                }
            }

            .promotion {
                /* padding: 1px; */
                position: relative;
                width: 90%;
                margin: 0 auto;

                button {
                    @include TMOBI300;
                    font-size: 14px;
                    letter-spacing: normal;
                    line-height: 1.54;
                    color: #fff;
                    background-color: #0064FF;
                    border-radius: 8px;
                    padding: 5px 15px;
                    position: absolute;
                    top: 27px;
                    right: 20px;
                }
            }
        }
    }
}

.paymethod-header{
    background-color: #fff;
    width: 100%;
    height: 56px;
    display: flex;
    .header-title{
        @include TMOBI500;
        font-size: 18px;
        color: #171819;
        top: 15px;
        position: relative;
    }
}

.pnc {
    .info {
        padding: 160px 0 70px 0;
        .pnc-img {
            text-align: center;
            padding: 30px 20px;
            img {
                width: 320px;
                height: 210px;
            }
        }
        .title {
            padding: 0 25px;
            height: 120px;
            .main-text {
                @include TMOBI500;
                font-size: 24px;
                line-height: 32px;
                position: relative;
            }
            .sub-text {
                @include TMOBI300;
                font-size: 16px;
                color: #8C9299;
                position: relative;
                top: 20px;
            }
        }
        .station {
            margin: 10px;
            background-color: #F8F9FA;
            border-radius: 20px;
            height: auto;
            padding: 30px 20px;
            .notice {
                display: flex;
                padding: 5px 0;
                p {
                    @include TMOBI500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #0064FF;
                    position: relative;
                    left: 10px;
                    padding: 5px 0;
                }
            }
            .item {
                padding: 15px 0;
                .main-text {
                    @include TMOBI500;
                    font-size: 18px;
                    line-height: 24px;
                    padding: 5px 0;
                }
                .sub-text {
                    @include TMOBI300;
                    font-size: 14px;
                    line-height: 20px;
                    color: #6E747A;
                    padding: 5px 0;
                }
                .second-sub-text {
                    @include TMOBI300;
                    font-size: 12px;
                    line-height: 18px;
                    color: #8C9299;
                }
            }
            .list {
                .main-text {
                    @include TMOBI500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #6E747A;
                    padding: 5px 0;
                }
                .item {
                    background-color: #EEF0F3;
                    border-radius: 20px;
                    padding: 15px 20px;
                    margin: 5px 0;
                    position: relative;

                    .name {
                        @include TMOBI500;
                        font-size: 14px;
                        line-height: 22px;
                        color: #000;
                    }
                    .available {
                        display: flex;
                        align-items: center;
                        .available-type {
                            @include TMOBI500;
                            font-size: 12px;
                            line-height: 20px;
                            color: #00ACE5;
                        }
                        .available-count {
                            @include TMOBI300;
                            font-size: 12px;
                            line-height: 20px;
                            color: #00ACE5;
                            margin-right: 10px;
                        }
                    }
                    .distance {
                        @include TMOBI300;
                        font-size: 12px;
                        line-height: 20px;
                        color: #6E747A;
                    }
                    img {
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        width: 20px;
                    }
                }
            }
        }
        .car-info {
            padding: 25px;
            margin-bottom: 10px;
            .car-title {
                display: flex;
                position: relative;
                .delete-btn {
                    @include TMOBI300;
                    font-size: 14px;
                    line-height: 20px;
                    color: #8C9299;
                    margin-left: auto;
                    margin-top: 12px;
                }
            }
            .main-text {
                @include TMOBI500;
                font-size: 18px;
                line-height: 24px;
                padding: 10px 0;
            }
            .sub-text {
                @include TMOBI300;
                font-size: 16px;
                line-height: 24px;
                color: #51565C;
            }
            .check {
                padding: 10px 10px 0 20px;
                display: flex;
                .check-text {
                    @include TMOBI300;
                    font-size: 14px;
                    line-height: 20px;
                    margin-left: 10px;
                    padding: 10px 0;
                }
                .check-img {
                    padding: 0 10px;
                }
            }
            .agree-item {
                padding: 20px 20px 0 50px;
                .first {
                    @include TMOBI300;
                    font-size: 14px;
                    line-height: 20px;
                    color: #8C9299;
                    padding: 0 0 20px 0;
                }
                .first-item {
                    border-top: 1px solid #EEF0F3;
                    display: flex;
                    align-items: center;
                    padding: 10px 5px;
                    .item-title {
                        @include TMOBI300;
                        font-size: 11px;
                        line-height: 16px;
                        color: #8C9299;
                        width: 100px;
                        flex-shrink: 0;
                    }
                    .item-content {
                        @include TMOBI300;
                        font-size: 11px;
                        line-height: 16px;
                        color: #171819;
                    }
                    .item-content-bold {
                        @include TMOBI500;
                        font-size: 11px;
                        line-height: 16px;
                        color: #171819;
                    }
                }
                .info-text {
                    @include TMOBI300;
                    font-size: 11px;
                    line-height: 16px;
                    color: #8C9299;
                    padding: 10px 0 0 0;
                }
            }
            .info-box {
                border-radius: 8px;
                border: 1px solid #E0E2E8;
                display: flex;
                position: relative;
                align-items: center;
                height: 120px;
                .car-img {
                    width: 112px;
                    height: 63px;
                    margin: 30px;
                }
                .info-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .info-brand {
                        @include TMOBI300;
                        color: #51565C;
                        font-size: 12px;
                        line-height: 18px;
                    }
                    .info-model {
                        @include TMOBI500;
                        color: #000;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .info-mac {
                        @include TMOBI300;
                        color: #8C9299;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                .delete-img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    margin-left: auto;
                    right: 10px;
                    top: 10px;
                }
            }
        }
    }
}

.footer-button {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 20px 20px;
    width: 100%;
    background: #fff;
    z-index: 9;

    &:before{
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    a {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 50px;
        color: #fff;
        background: #0064ff;
        border-radius: 8px;
    }

    button {
        display: block;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 50px;
        color: #fff;
        background: #0064ff;
        border-radius: 8px;
    }
}
.dim-footer-button {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 20px 20px;
    width: 100%;
    background: #fff;
    z-index: 9;

    &:before{
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        display: block;
        width: 100%;
        height: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    a {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 50px;
        color: #BEC5CC;
        background: #F8F9FA;
        border-radius: 8px;
    }

    button {
        display: block;
        text-align: center;
        font-size: 17px;
        @include TMOBI500;
        line-height: 50px;
        color: #fff;
        background: #0064ff;
        border-radius: 8px;
    }
}
.regist-sheet {
    padding: 10px 20px 100px 20px;
    .title {
        padding: 20px 30px;
        .main-text {
            @include TMOBI500;
            font-size: 20px;
            text-align: center;
            color: #171819;
        }
        .sub-text {
            @include TMOBI300;
            font-weight: 250;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #8C9299;
            margin-top: 5px;
        }
    }
    .category {
        .item {
            height: 68px;
            border-radius: 8px;
            border: 1px solid #E0E2E8;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            // align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: #fff;
            .name {
                color: #8C9299;
                @include TMOBI300;
                font-size: 18px;
                line-height: 34px;
                margin-left: 15px;
            }
            .selected-name {
                color: #171819;
                @include TMOBI500;
                font-size: 18px;
                line-height: 28px;
                margin-left: 15px;
            }
            img {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                // width: 20px;
                // margin-left: auto;
                // margin-right: 15px;
            }
        }
    }

    .select-box{
        display: flex;
        justify-content: space-between;
        border: 1.5px solid #E0E2E8;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 10px 15px;
        align-items: center;
        line-height: 1.5;

        .info-brand {
                @include TMOBI500;
                color: #000;
                font-size: 16px;
        }
    
        .info-model {
            @include TMOBI300;
            color: #8C9299;
            font-size: 12px;
        }

        img{
            width: 25%;
        }
    }
    .info-text{
        @include TMOBI300;
        color: #6E747A;
        font-size: 14px;
        display: flex;
        letter-spacing: normal;
    }
}
.detail {
    margin: 30px 0;
    .title {
        @include TMOBI500;
        color: #171819;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }
}
.detail-list {
    padding: 0 0 16px 0;
    .item {
        display: flex;
        height: 56px;
        padding: 16px 20px;
        align-items: center;
        .name {
            @include TMOBI300;
            font-size: 18px;
            line-height: 26px;
        }
        img {
            margin-left: auto;
        }
    }

    .all-check{
        display: flex;
        position: relative;
        align-items: center;
        padding: 0 35px;
        img {
            margin-left: auto;
            width: 20px;
        }
        .all-btn {
            @include TMOBI300;
            font-size: 14px;
            line-height: 20px;
            color: #171819;
            margin-left: 5px;
        }
    }
    .cpo-list {
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow: scroll;
        z-index: 100;
        // padding-bottom: 90px;
        height: 50%;
        max-height: 90%;
        overflow-y: auto;

        ul {
            padding: 8px 20px ;

            li {
                position: relative;
                padding: 15px 75px 15px 15px;

                input[type=checkbox] {
                    display: none;
                }

                input[type=checkbox]+label {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/images/common/check-none.svg') no-repeat;
                    background-size: cover;
                }

                .filter-checkbox {
                    padding: 0 0 0 30px;
                    z-index: inherit
                }

                .filter-checkbox input[type=checkbox]:checked+label {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/images/common/check.svg') no-repeat;
                }

                h3 {
                    @include TMOBI300;
                    font-size: 17px;
                    line-height: 24px;
                    color: #000;
                }

                .filter-img {
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    // background: url(../../assets/images/icon-check.svg) no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

.pluglink-promotion {
    background-color: #FBFDFF;
    .back-btn {
        position: absolute;
        width: 30px;
        height: 30px;
        margin: 15px;
        z-index: 1;
    }

    .coupon {
        text-align: center;
        position: relative;
        bottom: 100px;

        .coupon-btn {
            background-color: #111;
            color: #fff;
            font-size: 15px;
            @include TMOBI500;
            position: relative;
            width: 85%;
            padding: 16px 20px;
            border-radius: 8px;
            z-index: 1;
            letter-spacing: normal;
        }
    }
    .description{
        padding-top: 10px;
        margin-bottom: 40px;

        h1 {
            @include TMOBI500;
            font-size: 5.7vw;
            text-align: center;
            padding-bottom: 25px;
            letter-spacing: normal;
        }

        .list{
            display: flex;
            padding: 5px 40px;
            align-items: center;
            .li-detail{
                padding: 20px;
                line-height: 1.45;
                letter-spacing: normal;
                h3 {
                    @include TMOBI500;
                    // font-size: 18px;
                    font-size: 4.5vw;
                    padding-bottom: 12px;
                }
                span {
                    @include TMOBI300;
                    // font-size: 14px;
                    font-size: 3.7vw;
                    letter-spacing: 0.2px;
                }
            }
            img{
                width: 100px;
                height: 100px;
            }
        }
    }

    .info {
        background-color: #fff;
        padding: 20px;
        letter-spacing: normal;
        line-height: 1.5;
        padding-bottom: 100px;

        h3 {
            @include TMOBI500;
            font-size: 4vw;
            padding: 35px 0 5px 0;
        }
    
        li {
            @include TMOBI300;
            // font-size: 14px;
            font-size: 3.5vw;
            color: #666;
            margin: 10px 0;
            padding: 0 15px;
            // list-style-position: inside;
            // text-indent: -20px;
            text-align: justify;
            position: relative;
            left: 8px;
            // letter-spacing: 0.2px;
        }
    }
}

//마퀴 애니매이션 적용
@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.marquee {
    width: 150px;
    // overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    animation: marquee 6s linear infinite;
}

.cvc-box {
    .check {
        position: relative;

        input[type="checkbox"] {
            opacity: 0;
            position: relative;
            width: 20px;
            height: 20px;
            cursor: pointer;
            z-index: 2;

            &:checked + label {
                color: #111;

                &:before {
                    border-color: #0064ff;
                }
            }
        }

        label {
            position: relative;
            @include TMOBI300;
            font-size: 14px;
            line-height: 1.47;
            color: #111;


            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                left: -16px;
                width: 6px;
                height: 11px;
                border-right: 2px solid #ccc;
                border-bottom: 2px solid #ccc;
                transform: rotate(45deg);
                pointer-events: none; // 체크박스 클릭을 방해하지 않도록 설정
            }
        }
    }
}