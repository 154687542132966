@charset "UTF-8";

.popup {
  &_wrap {
    @include position(0, 0, 0, 0, 10000, fixed);
  }
  &_container {
    @include position(114px, 10px, 114px, 10px, 100);
    overflow: hidden;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    background: $C-white;
  }
  &_content {
    height: calc(100% - 76px);
  }
  &_footer {
    margin-top: 26px;

    .btn_confirm {
      overflow: hidden;
      width: 100%;
      height: 50px;
      border-radius: 5px;
      background: $C-primary;
      color: $C-white;
      font-size: $F-size;

      &:disabled {
        background: #e1e1e1;
      }
    }
  }
  &_dimmed {
    @include position(0, 0, 0, 0);
    background: rgba(0, 0, 0, .5);
  }
  &_terms {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px 10px 0;

    &_inner {
      flex: 1;
      overflow: auto;

      .title {
        margin: 0 0 20px;
        color: $C-black;
        line-height: 26px;
        font-size: 20px;
        font-family: $F-family-medium;

        .line {
          display: inline-block;
        }
      }
      .cont {
        color: $C-font-blurry;
        line-height: 22px;
        font-size: 15px;
        text-align: justify;

        .tit {
          display: block;
        }
      }
    }
    .checkbox_area {
      margin-top: 20px;

      .label {
        position: relative;
        padding-left: 32px;
        color: $C-black;
        line-height: 24px;
        font-size: $F-size;

        &::before {
          @include position(0, null, null, 0);
          content: '';
          width: 24px;
          height: 24px;
          background: url("../../assets/images/common/btn-check-off.svg") no-repeat;
          background-size: 100% 100%;
        }
      }
      input[type=checkbox] {
        &:checked {
          + .label {
            &::before {
              background-image: url("../../assets/images/common/btn-check-on.svg");
            }
          }
        }
      }
    }
  }
}

.modal {
  &_wrap {
    @include position(0, 0, 0, 0, 10000, fixed);
    @include flex;
    @include flex-align(center);
    justify-content: center;
  }
  &_container {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
    overflow: hidden;
    position: relative;
    z-index: 10;
    width: calc(100% - 50px);
    max-width: 310px;
    margin: 0 25px;
    border-radius: 8px;
    background: $C-white;
  }
  &_header {
    margin-top: 37px;

    .title {
      color: $C-black;
      line-height: 22px;
      font-size: $F-size-big;
      font-family: $F-family-medium;
      text-align: center;
    }
    + .modal_content {
      margin: 15px 0 37px;
    }
  }
  &_content {
    margin: 40px 0;
    padding: 0 20px;
    text-align: center;
  }
  &_footer {
    @include flex;
    @include flex-direction(row);

    .btn_cancel,
    .btn_confirm {
      width: 100%;
      height: 50px;
      color: $C-white;
      font-size: $F-size;
    }
    .btn_cancel {
      color: #171819;
      background: #EEF0F3;
    }
    .btn_confirm {
      background: $C-primary;

      &:disabled {
        background: #bfbfbf;
      }
    }
  }
  &_dimmed {
    @include position(0, 0, 0, 0, 1);
    background: rgba(0, 0, 0, .5);
  }
  &_msg {
   // color: $C-black;
    color: #8C9299;
    text-align: center;
    line-height: 22px;
    font-size: $F-size-normal;
    padding-top: 10px;
    font-family: 'TMOBI300';
  }
// 모달 상단 창에 들어갈 head
  &_msg_head{
    text-align: center;
    line-height: 22px;
    font-size: $F-size-big;
    // font-size: medium;
    // font-weight: bolder;
    color: #171819;
    font-family: 'TMOBI300';
  }
  &_coupon_input {
    margin: 27px 5px 0;

    .inp_text {
      width: 100%;
      height: 52px;
      border: 0;
      border-bottom: 1px solid #e0e0e0;
      font-size: $F-size-big;

      &::placeholder {
        color: #e0e0e0;
      }
      &:focus {
        border-bottom-color: $C-primary;
      }
    }
  }
}

@media (orientation: landscape) and (min-width: 420px) {
  .popup {
    &_container {
      @include position(20px, 80px, 20px, 80px, 100);
    }
  }
}
