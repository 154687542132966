@charset "UTF-8";

.terms {
  margin: 32px 20px 0;
  padding-bottom: 40px;

  &_title {
    color: $C-black;
    line-height: 26px;
    font-size: 20px;
    font-family: $F-family-medium;
  }
  &_content {
    margin-top: 20px;
    color: #666;
    line-height: 23px;
    text-align: justify;
    font-size: $F-size-normal;

    .tit {
      display: block;
      font-family: $F-family-medium;
    }
  }
}