@charset "UTF-8";

// variables.scss

// $base- reset용 기본 변수입니다.
// $F- 폰트 관련 변수입니다.
// $C- 컬러 관련 변수입니다.
// $G- 글로벌 변수입니다.
// $M- Mixin 변수입니다.

// layout Width
$G-contents: 100%;
$G-contents-gap: 24px;
$G-heights: 100%;

// Base Font Style
$base-font-family: 'TMOBI300', 'Noto Sans', 'Roboto', AppleSDGothicNeo, AppleSDGothicNeo-Regular, '나눔고딕', NanumGothic, '돋움', Dotum, '굴림', Gulim, Helvetica, HelveticaNeue, sans-serif;
$base-font-size: 10px;
$base-line-height: 1.5;

$F-family-medium: 'TMOBI500', 'Noto Sans', 'Roboto', AppleSDGothicNeo, AppleSDGothicNeo-Regular, '나눔고딕', NanumGothic, '돋움', Dotum, '굴림', Gulim, Helvetica, HelveticaNeue, sans-serif;
$F-family-bold: 'TMOBI700', HelveticaNeue-Bold, AppleSDGothicNeo-Bold, sans-serif;

// Font size
$F-size-big: 18px;
$F-size: 16px;
$F-size-normal: 14px;
$F-size-small: 12px;

// Font weight
$F-weight-reset: normal;

// Color
$C-primary: #0064ff; //primary
$C-point: #e55545; //orange-red

// Font color
$C-font-light: #909090; //밝은 폰트컬러
$C-font-blurry: #a1a3a6; //흐릿 폰트컬러
$C-font-gray: #808080;

//basic color
$C-tr: transparent; //투명
$C-white: #ffffff; //흰색
$C-black: #000000; //검정색
