@charset "UTF-8";

.blind {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

// input radio
.radio_area {
  position: relative;

  input[type=radio] {
    &:checked {
      + .label {
        &::after {
          background-image: url("../../assets/images/common/btn-radio-on.svg");
        }
      }
    }
  }
  .label {
    display: block;
    overflow: hidden;
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 28px;
      height: 28px;
      margin: 10px;
      background: url("../../assets/images/common/btn-radio-off.svg") no-repeat;
    }
    .text {
      display: block;
    }
  }
}

// Loading
.loading-indicator {
  overflow: hidden;
  z-index: 999999;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.3);
}